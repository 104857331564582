import {Component} from '@angular/core';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {Router} from '@angular/router';
import {FincasApiService} from '../../../../service/api/fincas-api.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import { StorageManager } from '../../../../common/storage-manager.class';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {Utils} from '../../../../common/utils';
import {BaseView} from '../../../base-view';
import {environment} from '../../../../../environments/environment';
import { Filtering } from 'src/app/service/filtering/filtering';

import type { OnInit, AfterViewInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

@Component({
    selector: 'app-sectores',
    templateUrl: './sectores.component.html',
    styleUrls: ['./sectores.component.scss']
})
export class SectoresComponent extends BaseView<SectorModel> implements OnInit, AfterViewInit {

    public appName = environment.appName;
    public rol: string = StorageManager.getUser().rol;

    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public showSectorPlantas = environment.features.showSectorPlantas;

    public canAddSectores = environment.features.canAddSectores;
    public canEditSectores = environment.features.canEditSectores;
    public canDeleteSectores = environment.features.canDeleteSectores;

    public filter: Filtering<SectorModel> = new Filtering<SectorModel>();
    public getRequest: RequestHandler<SectorModel[]> = this.fincasApi.sectoresGET;
    public deleteRequest: RequestHandler<SectorModel> = this.fincasApi.sectoresDELETE;
    public pathToForm = 'sectores-form';
    public menuItemId = 'sectores';
    public textoSuperficie = environment.features.textoSuperficie;


    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: Record<string, boolean> = {};
    public override model = {} as SectorModel;

    public btnAnadir = `Añadir ${this.sectorName}`;

    public formFields: ItemInterface<object>[] = [
        {
            field: 'nombre', 
            label: `Nombre ${this.sectorName}`, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'finca', 
            label: this.fincaName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'partida', 
            label: 'Partida', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.showPartida
        },
        {
            field: 'goteros', 
            label: this.appName === 'vginer' ? 'Nº Goteros Totales' : 'Nº Goteros/Árbol',
            inputType: {type: InputType.TEXT}, 
            visible: this.appName !== 'gardengest'
        },
        {
            field: 'caudal_gotero', 
            label: 'Caudal Goteros (l/h)', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName !== 'gardengest'
        },
        {
            field: 'caudal_cuba', 
            label: 'Capacidad Cuba (l)', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName !== 'gardengest'
        },
        {
            field: 'caudal_inyectora', 
            label: 'Caudal Inyectora (l/h)', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName !== 'gardengest'
        },
        {
            field: 'sup_cultivada', 
            label: 'S.\xa0cultivada\xa0(' + this.textoSuperficie + ')', 
            inputType: {type: InputType.TEXT}, visible: this.appName !== 'gardengest'
        },
        {
            field: 'distancia_goteros', 
            label: 'Distancia entre Goteros', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'benibal'
        },
        {
            field: 'total_plantas', 
            label: 'Nº\xa0Plantas', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName !== 'gardengest'
        },
    ];

    public cols: ColInterface[] = [
        {field: 'nombre', header: 'Nombre', filter: '', width: '200px'},
        {field: 'finca', header: this.fincaName, filter: '', width: '160px'},
        {field: 'partida', header: 'Partida', filter: '', visible: environment.features.showPartida},
        {field: 'goteros', header: 'Nº Goteros/Árbol', filter: '', width: '120px', visible: this.appName !== 'gardengest'},
        {field: 'caudal_gotero', header: 'Caudal Goteros (l/h)', filter: '', width: '120px', visible: this.appName !== 'gardengest'},
        {field: 'caudal_cuba', header: 'Capacidad Cuba (l)', filter: '', width: '120px', visible: this.appName !== 'gardengest'},
        {field: 'caudal_inyectora', header: 'Caudal Inyectora (l/h)', filter: '', width: '120px', visible: this.appName !== 'gardengest'},
        {
            field: 'sup_cultivada', 
            header: 'S.\xa0cultivada\xa0(' + this.textoSuperficie + ')', 
            footer: '', 
            visible: this.appName !== 'gardengest'
        },
        {field: 'total_plantas', header: 'Nº\xa0Árboles/Plantas', footer: '', visible: this.appName !== 'gardengest'},
    ];

    constructor(public fincasApi: FincasApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, fincasApi.sectoresGET, fincasApi.sectoresDELETE);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);
    
    }

    ngAfterViewInit() {
        this.fincasApi.sectoresGET.response(() => {
            this.updateFooter();
        });
    }

    public add() {
        this.router.navigate(['dashboard', 'crear-sector']);
    }

    public edit(data: SectorModel) {
        this.router.navigate(['dashboard', 'editar-sector', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.perform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public override show(modal: string, data: SectorModel) {
        super.show(modal, data);
        Object.keys(this.model).forEach(key => {
            if (['goteros', 'caudal_cuba', 'caudal_inyectora', 'total_plantas'].includes(key)) {
                this.model[key] = Utils.decimalFormat(this.model[key]?.toString().replace(',', '.') ?? '');
            }
            if (['caudal_gotero', 'sup_cultivada'].includes(key)) {
                this.model[key] =
                    // TODO Cannot read property 'replace' of null ???
                     Utils.decimalFormat(this.model[key]?.toString()?.replace(',', '.') ?? '', 2); 
            }
        });
    }

    public override hide(modal: string) {
        this.model = {} as SectorModel;
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {

        if (col.field === 'finca') {
            return {
                textAlign: 'left'
            };
        }// if();


        if (( Utils.isNumber(value) && col.field !== 'nombre') || 
            col.field === 'sup_cultivada') {
            return {
                textAlign: 'right',
                paddingRight: '40px'
            };
        }// if();

        return {};
    }// ();

    public globalVisualTransform(value: string, col: { field: string; }) {
        if (['caudal_gotero', 'sup_cultivada'].includes(col.field)) {
            if (value && (value.indexOf(',') !== -1)) {
                value = value.replace(',', '.');
            }
            return Utils.decimalFormat(value, 2);
        } else if (Utils.isNumber(value) && col.field !== 'finca') {
            return Utils.decimalFormat(value);
        }
        return value;
    }

    public override sum(field: string) {
        let sum = 0;

        (this.filter.value || []).forEach(it => {
            sum += parseFloat(it[field]?.toString() || '0');
        });

        this.cols.forEach(it => {
            if (it.field === field) {
                it.footer = Utils.decimalFormat(sum + '', 2);
                if (field === 'total_plantas') {
                    it.footer = Utils.decimalFormat(sum + '');
                }
            }
        });
    }

    public override updateFooter() {
        ['sup_cultivada', 'total_plantas'].forEach(it => this.sum(it));
    }
}
