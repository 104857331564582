import { Component } from '@angular/core';
import { DashboardService } from 'src/app/service/events/dashboard.service';
import { BaseView } from 'src/app/view/base-view';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { CitasApiService } from 'src/app/service/api/citas-api.service';
import { environment } from 'src/environments/environment';
import moment from 'moment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { CitasModel } from 'src/app/models/citas/citas.model';

@Component({
    selector: 'app-visitas-consultas',
    templateUrl: './visitas-consultas.component.html',
    styleUrls: ['./visitas-consultas.component.scss']
})
export class VisitasConsultasComponent extends BaseView<CitasModel> implements OnInit {

    public showVisitasConsultasObservaciones = environment.features.showVisitasConsultasObservaciones;
    public hasClients = environment.features.hasClients;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public showVisitasHora = environment.features.showVisitasHora;
    public hasVisitasHoraInicioFin = environment.features.hasVisitasHoraInicioFin;
    public clienteName = environment.features.clienteName;
    public showMotivo = environment.features.showMotivo;

    public getRequest: RequestHandler<CitasModel[]> = this.citasApi.citas.GET;
    public menuItemId = 'visitas-consultas';

    public formFields: ItemInterface<object>[] = [
        {
            label: 'Fecha', 
            field: 'fecha', 
            inputType: {type: InputType.CALENDAR}
        },
        {
            label: 'Hora', 
            field: 'hora', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showVisitasHora
        },
        {
            label: 'Duración', 
            field: 'duracion', 
            inputType: {type: InputType.TEXT}, 
            visible: this.hasVisitasHoraInicioFin
        },
        {
            label: 'Cultivo', 
            field: 'cultivo_nombre', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Variedad', 
            field: 'variedad_nombre', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: this.clienteName, 
            field: 'cliente_nombre', 
            inputType: {type: InputType.TEXT}, 
            visible: this.hasClients
        },
        {
            label: this.fincaName, 
            field: 'finca_nombre', 
            inputType: {type: InputType.TEXT}
        },    
        {
            label: 'Visitador', 
            field: 'visitador_nombre', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Estado Arbolado', 
            field: 'estado_arbolado', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Moviendo', 
            field: 'moviendo', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Cosecha', 
            field: 'estado_cosecha', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Tamaño', 
            field: 'tamanyo', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Humedad', 
            field: 'humedad', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Floración', 
            field: 'estado_floracion', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Observaciones', 
            field: 'observaciones', 
            inputType: {type: InputType.TEXT},
            visible: this.showVisitasConsultasObservaciones
        },

        // Separadores
        {
            hasLabel: false, 
            field: '', 
            inputType: {type: InputType.EMPTY}, 
            visible: !this.hasClients
        },
        {
            hasLabel: false, 
            field: '', 
            inputType: {type: InputType.EMPTY}, 
            visible: !this.showVisitasConsultasObservaciones
        },
        {
            hasLabel: false, 
            field: '', 
            inputType: {type: InputType.EMPTY}
        },
        {
            hasLabel: false, 
            field: '', 
            inputType: {type: InputType.EMPTY}
        },
        {
            label: 'Plaga 1', 
            field: 'plaga1', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Estado Plaga 1', 
            field: 'estado_plaga1', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Plaga 2', 
            field: 'plaga2', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Estado Plaga 2', 
            field: 'estado_plaga2', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Plaga 3', 
            field: 'plaga3', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Estado Plaga 3', 
            field: 'estado_plaga3', 
            inputType: {type: InputType.TEXT}
        },
        {
            label: 'Plaga 4', 
            field: 'plaga4', 
            inputType: {type: InputType.TEXT}
        },    
        {
            label: 'Estado Plaga 4', 
            field: 'estado_plaga4', 
            inputType: {type: InputType.TEXT}
        },
    ];

    public cols: ColInterface[] = [
        {field: 'fecha', header: 'Fecha'},
        {field: 'hora', header: 'Hora', visible: this.showVisitasHora},
        {field: 'duracion', header: 'Duración', visible: this.hasVisitasHoraInicioFin},
        {field: 'cliente_nombre', header: this.clienteName, visible: this.hasClients},
        {field: 'finca_nombre', header: this.fincaName},
        {field: 'cultivo_nombre', header: 'Cultivo'},
        {field: 'variedad_nombre', header: 'Variedad'},
        {field: 'visitador_nombre', header: 'Visitador'},
        {field: 'motivo', header: 'Motivo', visible: this.showMotivo},
        {field: 'observaciones', header: 'Observaciones', visible: this.showVisitasConsultasObservaciones}
    ];

    constructor(private dashboard: DashboardService, private citasApi: CitasApiService) {
        super(dashboard, citasApi.citas.GET);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);
    }

    public globalConditionalStyle(_value: string, cell: ColInterface) {
        if (cell.header === 'Fecha'
        || cell.header === 'Hora'
        || cell.header === 'Visitador'
        || cell.header === 'Realizada'
        || cell.header === 'Duración') {

            return {
                textAlign: 'center'
            };
        }
        else {
            return {
                marginLeft: '40px;'
            };
        }
    }

    public globalVisualTransform(value: string, col: ColInterface, row: CitasModel) {
        if (col.field === 'realizada') {
            return (value === '1') ? 'Sí' : 'No';
        }
        if ((col.field === 'duracion') && row.visita && row.visita.hora_inicio && row.visita.hora_fin) {
            const fin = moment(row.visita.hora_fin, 'HH:mm');
            const inicio = moment(row.visita.hora_inicio, 'HH:mm');
            const diff = fin.subtract(inicio.get('hours'), 'hours').subtract(inicio.get('minutes'), 'minutes');
            return diff.format('HH:mm');
        } else {
            return value;
        }
    }
}
