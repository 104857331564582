import { createCsv } from 'src/app/common/createCsv';
import {TareasApiService} from '../../../../service/api/tareas-api.service';
import { Component } from '@angular/core';
import { Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';
import moment, { Moment } from 'moment';
import { environment } from 'src/environments/environment';
import { Filtering } from 'src/app/service/filtering/filtering';
import {StorageManager} from '../../../../common/storage-manager.class';

import type { OnInit, AfterViewInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TareaRiegoModel } from 'src/app/models/tareas/tarea-riego.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

const TRUTHY_VALUES = [1, '1', true, 'true'];
@Component({
    selector: 'app-tareas-mto-riego',
    templateUrl: './tareas-mto-riego.component.html',
    styleUrls: ['./tareas-mto-riego.component.scss']
})
export class TareasMtoRiegoComponent extends BaseView<TareaRiegoModel> implements OnInit, AfterViewInit {

    public rolUser = StorageManager.getUser().rol;
    public hiddenButtonDelete = false;

    public appName = environment.appName;
    public serverUrl = environment.serverUrl;
    public fincaName = environment.features.fincaName; 
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
  (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));     

    public getRequest: RequestHandler<TareaRiegoModel[]> = this.tareasApi.mtoRiego.GET;
    public deleteRequest: RequestHandler<TareaRiegoModel> = this.tareasApi.mtoRiego.DELETE;
    public pathToForm = 'crear-riego';  
    public menuItemId = 'riego';  

    public override filtering: Filtering<TareaRiegoModel> = new Filtering<TareaRiegoModel>();
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;  

    public override canShow: Record<string, boolean> = {};
    public override model: TareaRiegoModel = {};
  
    public distributionOrder = [3];

    public formFields: ItemInterface<object>[] = 
        [
            {
                field: 'notas_cliente',
                label: 'Notas cliente',
                inputType: {type: InputType.TEXTAREA},
                visible: environment.features.showTareasMto ? true : false
            },
            {
                field: 'cliente_nombre', 
                name: 'Cliente', 
                label: 'Cliente', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'fecha', 
                name: 'Fecha', 
                label: 'Fecha', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'finca', 
                name: this.fincaName, 
                label: this.fincaName, 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'hora_inicio', 
                name: 'Hora Inicio', 
                label: 'Hora Inicio', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'hora_fin', 
                name: 'Hora Fin', 
                label: 'Hora Fin', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'horas_totales', 
                name: 'Horas', 
                label: 'Horas', 
                inputType: {type: InputType.TEXT} 
            },
            {
                field: 'mantenimiento', 
                name: 'Mantenimiento/Creacíon', 
                label: 'Mantenimiento', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
            {
                field: 'prioridad', 
                name: 'Prioridad/Realizada', 
                label: 'Prioridad/Realizada', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsHorarioRiego: ItemInterface<object>[] = 
        [ 
            {
                field: 'dias_nombre', 
                name: 'Días', 
                label: 'Días', 
                inputType: {type: InputType.TEXT}, 
                filter: (it: { value: { nombre: string; }; }) => it.value.nombre
            },
        ]; 
    
    public formFieldsHoraRiego: ItemInterface<object>[] =
        [
            {
                field: 'hora_inicio_riego', 
                name: 'Hora Inicio Riego', 
                label: 'Hora Inicio Riego', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'off_on', 
                name: 'OFF/ON', 
                label: 'OFF/ON', 
                inputType: {type: InputType.RO_MD_SWITCH}
            },
        ];

    public formFieldsSectores: ItemInterface<object>[] =
        [
            {
                field: 'minutos_sector1', 
                name: 'Sector 1 (minutos)', 
                label: 'Sector 1 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector1', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector2', 
                name: 'Sector 2 (minutos)', 
                label: 'Sector 2 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector2', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector3', 
                name: 'Sector 3 (minutos)', 
                label: 'Sector 3 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector3', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector4', 
                name: 'Sector 4 (minutos)', 
                label: 'Sector 4 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector4', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector5', 
                name: 'Sector 5 (minutos)', 
                label: 'Sector 5 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector5', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector6', 
                name: 'Sector 6 (minutos)', 
                label: 'Sector 6 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector6', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector7', 
                name: 'Sector 7 (minutos)', 
                label: 'Sector 7 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector7', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector8', 
                name: 'Sector 8 (minutos)', 
                label: 'Sector 8 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector8', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector9', 
                name: 'Sector 9 (minutos)', 
                label: 'Sector 9 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector9', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector10', 
                name: 'Sector 10 (minutos)', 
                label: 'Sector 10 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector10', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector11', 
                name: 'Sector 11 (minutos)', 
                label: 'Sector 11 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector11', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector12', 
                name: 'Sector 12 (minutos)', 
                label: 'Sector 12 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector12', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector13', 
                name: 'Sector 13 (minutos)', 
                label: 'Sector 13 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector13', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector14', 
                name: 'Sector 14 (minutos)', 
                label: 'Sector 14 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector14', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'minutos_sector15', 
                name: 'Sector 15 (minutos)', 
                label: 'Sector 15 (minutos)', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'metodo_sector15', 
                name: 'Método', 
                label: 'Método', 
                inputType: {type: InputType.TEXT}
            },
        ];
    public formFieldsAspersores: ItemInterface<object>[] = 
        [       
            {
                field: 'aspersores_medio', 
                name: 'Aspersores 1/2', 
                label: 'Aspersores 1/2', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'aspersores_tres_cuartos', 
                name: 'Aspersores 3/4', 
                label: 'Aspersores 3/4', 
                inputType: {type: InputType.TEXT}
            },
        ];
    
    public formFieldsDifusores: ItemInterface<object>[] =
        [
            {
                field: 'difusores', 
                name: 'Difusores', 
                label: 'Difusores', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'boquillas_difusor', 
                name: 'Boquillas Difusor', 
                label: 'Boquillas Difusor', 
                inputType: {type: InputType.TEXT}
            },
        ];
   
    public formFieldsArqueta: ItemInterface<object>[] =
        [
            {
                field: 'arquetas', 
                name: 'Arquetas', 
                label: 'Arquetas', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsArquetas: ItemInterface<object>[] =
        [
            {
                field: 'arquetas_rectangular', 
                name: 'Arquetas Rectangular', 
                label: 'Rectangular', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'arquetas_circular', 
                name: 'Arquetas Circular', 
                label: 'Circular', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'arquetas_cuadrada', 
                name: 'Arquetas Cuadrada', 
                label: 'Cuadrada', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'arquetas_baja', 
                name: 'Arquetas Baja', 
                label: 'Baja', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'arquetas_jumbo', 
                name: 'Arquetas Jumbo', 
                label: 'Jumbo', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'arquetas_jumbo_baja', 
                name: 'Arquetas Jumbo Baja', 
                label: 'Jumbo Baja', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsElectrovalvulas9vLatch: ItemInterface<object>[] =
        [
            {
                field: 'electrovalvulas9v_una_pulgada', 
                name: 'Electroválvulas 9v Latch 1""', 
                label: '1"', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'electrovalvulas9v_media_pulgada', 
                name: 'Electroválvulas 9v Latch 1" 1/2"', 
                label: '1 1/2"', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'electrovalvulas9v_dos_pulgadas', 
                name: 'Electroválvulas 9v Latch 2"', 
                label: '2"', 
                inputType: {type: InputType.TEXT}
            },
        ];

    

    public formFieldsElectrovalvulas24v: ItemInterface<object>[] =
        [
            {
                field: 'electrovalvulas24v_una_pulgada', 
                name: 'Electroválvulas 24v 1"', 
                label: '1"', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'electrovalvulas24v_media_pulgada', 
                name: 'Electroválvulas 24v 1 1/2"', 
                label: '1 1/2"', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'electrovalvulas24v_dos_pulgadas', 
                name: 'Electroválvulas 24v 2"', 
                label: '2"', 
                inputType: {type: InputType.TEXT}
            },
        ];

   
    
    public formFieldsSolenoides: ItemInterface<object>[] =
        [
            {
                field: 'solenoides_9v_latch', 
                name: 'Solenoides 9v Latch', 
                label: '9v Latch', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'solenoides_24v', 
                name: 'Solenoides 24v', 
                label: '24v', 
                inputType: {type: InputType.TEXT}
            },
        ];
    
    public formFieldsProgramadorPilas: ItemInterface<object>[] =
        [
            {
                field: 'programador_pilas_1', 
                name: 'Programador Pilas 1', 
                label: '1', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador_pilas_2', 
                name: 'Programador Pilas 2', 
                label: '2', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador_pilas_4', 
                name: 'Programador Pilas 4', 
                label: '4', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador_pilas_6', 
                name: 'Programador Pilas 6', 
                label: '6', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador_pilas_8', 
                name: 'Programador Pilas 8', 
                label: '8', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador_pilas_10', 
                name: 'Programador Pilas 10', 
                label: '10', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador_pilas_12', 
                name: 'Programador Pilas 12', 
                label: '12', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsProgramador220v: ItemInterface<object>[] =
        [
            {
                field: 'programador220v_1', 
                name: 'Programador 220v (estaciones) 1', 
                label: '1', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador220v_2', 
                name: 'Programador 220v (estaciones) 2', 
                label: '2', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador220v_4', 
                name: 'Programador 220v (estaciones) 4', 
                label: '4', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador220v_6', 
                name: 'Programador 220v (estaciones) 6', 
                label: '6', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador220v_8', 
                name: 'Programador 220v (estaciones) 8', 
                label: '8', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador220v_10', 
                name: 'Programador 220v (estaciones) 10', 
                label: '10', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'programador220v_12', 
                name: 'Programador 220v (estaciones) 12', 
                label: '12', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsCajaConexion: ItemInterface<object>[] =
        [
            {
                field: 'caja_conexion_1', 
                name: 'Caja Conexión (estaciones) 1', 
                label: '1', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'caja_conexion_2', 
                name: 'Caja Conexión (estaciones) 2', 
                label: '2', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'caja_conexion_4', 
                name: 'Caja Conexión (estaciones) 4', 
                label: '4', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'caja_conexion_6', 
                name: 'Caja Conexión (estaciones) 6', 
                label: '6', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsTuberiaGoteo: ItemInterface<object>[] =
        [
            {
                field: 'tuberia_goteo_33cm', 
                name: 'Tubería Goteo - Gotero a: (ml) 33cm', 
                label: '33cm', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_goteo_50cm', 
                name: 'Tubería Goteo - Gotero a: (ml) 50cm', 
                label: '50cm', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsTuberiaLisa: ItemInterface<object>[] =
        [
            {
                field: 'tuberia_lisa_16mm', 
                name: 'Tubería Lisa (ml) 16mm', 
                label: '16mm', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsPilas: ItemInterface<object>[] =
        [
            {
                field: 'pilas_9v', 
                name: 'Pilas 9v', 
                label: '9v', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'pilas_otras', 
                name: 'Pilas Otras', 
                label: 'Otras', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsTuberiaPE01: ItemInterface<object>[] = 
        [      
            {
                field: 'tuberia_pe_20mm', 
                name: 'Tubería P.E. (Diámetro) 20mm', 
                label: '20mm', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pe_32mm', 
                name: 'Tubería P.E. (Diámetro) 32mm', 
                label: '32mm', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pe_40mm', 
                name: 'Tubería P.E. (Diámetro) 40mm', 
                label: '40mm', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pe_50mm', 
                name: 'Tubería P.E. (Diámetro) 50mm', 
                label: '50mm', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pe_63mm', 
                name: 'Tubería P.E. (Diámetro) 63mm', 
                label: '63mm', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pe_75mm', 
                name: 'Tubería P.E. (Diámetro) 75mm', 
                label: '75mm', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsTuberiaPE02: ItemInterface<object>[] = 
        [      
            {
                field: 'tuberia_pe_16', 
                name: 'Tubería P.E. 16--2,5', 
                label: '16--2,5', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pe_20', 
                name: 'Tubería P.E. 20--6', 
                label: '20--6', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pe_25', 
                name: 'Tubería P.E. 25--6', 
                label: '25--6', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pe_32', 
                name: 'Tubería P.E. 32--6', 
                label: '32--6', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pe_40', 
                name: 'Tubería P.E. 40--6', 
                label: '40--6', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pe_50', 
                name: 'Tubería P.E. 50--6', 
                label: '50--6', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pe_63', 
                name: 'Tubería P.E. 63--6', 
                label: '63--6', 
                inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsTuberiaPVC: ItemInterface<object>[] = 
        [      
            {
                field: 'tuberia_pvc_20mm', 
                name: 'Tubería P.E. 20mm', 
                label: '20mm', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pvc_32mm', 
                name: 'Tubería P.E. 32mm', 
                label: '32mm', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pvc_40mm', 
                name: 'Tubería P.E. 40mm', 
                label: '40mm', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pvc_50mm', 
                name: 'Tubería P.E. 50mm', 
                label: '50mm', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pvc_63mm', 
                name: 'Tubería P.E. 63mm', 
                label: '63mm', 
                inputType: {type: InputType.TEXT}
            },
            {
                field: 'tuberia_pvc_75mm', 
                name: 'Tubería P.E. 75mm', 
                label: '75mm', 
                inputType: {type: InputType.TEXT}
            },
        ];
    
    public formFieldsRiegoManguera: ItemInterface<object>[] = 
        [      
            {
                field: 'riego_manguera', 
                name: 'Riego con Manguera', 
                label: '', inputType: {type: InputType.TEXT}
            },
        ];

    public formFieldsTrabajadores: ItemInterface<object>[] = [
        {
            field: 'trabajadores_nombres', 
            name: 'Profesional(es)', 
            label: 'Profesional(es)', 
            inputType: {type: InputType.TEXTAREA}
        }, 
    ];
    
    public formFieldsOrdenTrabajo: ItemInterface<object>[] = 
        [  
            {
                field: 'orden_trabajo', 
                name: 'Orden de trabajo', 
                label: 'Orden de trabajo', 
                inputType: {type: InputType.TEXTAREA}
            }
        ];
  
    public formFieldsObs: ItemInterface<object>[] = 
        [  
            {
                field: 'observaciones', 
                name: 'Observaciones', 
                label: 'Observaciones', 
                inputType: {type: InputType.TEXTAREA}
            },
        ];
    
    public prioridad = {
        selected: ''
    };

    public totalHoras: Moment | string = '00:00';
    public auxTotalHoras: Moment | string = '00:00';
    public fecha: {inicio: string; fin: string} = {
        inicio: '',
        fin: '',
    };

    public cols: ColInterface[] = [
        {field: 'cliente_nombre', header: 'Cliente', filter: ''},
        {field: 'trabajadores_nombres', header: 'Empleado', filter: ''},
        {field: 'finca', header: this.fincaName, filter: ''},
        {field: 'fecha', header: 'Fecha', filter: ''},
        {field: 'hora_inicio', header: 'Hora Inicio', filter: ''},
        {field: 'hora_fin', header: 'Hora Fin', filter: ''},
        {field: 'horas_totales', header: 'Horas', filter: ''},
        {field: 'prioridad', header: 'Prioridad/Realizada', filter: ''},
        {field: 'observaciones', header: 'Observaciones', filter: ''}
    ];  

    constructor(public tareasApi: TareasApiService,
        private dashboard: DashboardService,
        private router: Router) {
        super(dashboard, tareasApi.mtoRiego.GET, tareasApi.mtoRiego.DELETE);
  
  
    }

    ngOnInit() {
        if (environment.features.showTareasMto){
            if (this.rolUser !== 'admin'){
                this.hiddenButtonDelete = true;
            }
            this.prioridad.selected = 'No-Realizada';
        }
        this.dashboard.setSubmenuItem(this.menuItemId);
        this.filtering.addFilter((copy: TareaRiegoModel[] | void) => (copy as TareaRiegoModel[]).filter(row => {
            switch (this.prioridad.selected) {
            case 'Realizada':
                return ['Realizada'].includes(row.prioridad ?? '');
            case 'No-Realizada':
                return !['Realizada'].includes(row.prioridad ?? '');
            default:
                return true;
            }
        }));

        /*** 
   *  En el caso de que el usuario no se admin,
   * filtrará por su id de usuario que coincide,
   *  con el id trabajador   
  */
        if ( StorageManager.getUser().rol !== 'admin' ){ 
            this.filtering.addFilter((copy: TareaRiegoModel[] | void) => (copy as TareaRiegoModel[]).filter(row => {
          
                if (row.ids_trabajadores != null ) {
            
                    const idTrabajador = StorageManager.getUser().id;
                    const listaTrabajadores = (row.ids_trabajadores as string).split(';');
                    if ( listaTrabajadores.includes(idTrabajador)) {
                        return true;
                    }
            
                }
                return false;
            }));
        }
  
        /*** 
     *  Fin de filtrar por id_usuario = trabajador,
     *   
    */
        this.filtering.addFilter((copy: TareaRiegoModel[] | void) => (copy as TareaRiegoModel[]).filter(row => {
            if (this.fecha.inicio && this.fecha.fin && row.fecha) {
  
                const arrayFecha = row.fecha.toString().split('/');
                const fechaAux = arrayFecha[2] + '-' + arrayFecha[1] + '-' + arrayFecha[0];
  
                if (
                    (moment(moment(this.fecha.inicio).format('YYYY-MM-DD')).diff(fechaAux) <= 0) && 
                  (moment(moment(this.fecha.fin).format('YYYY-MM-DD')).diff(fechaAux) >= 0)
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }));
    }

    public add() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', this.pathToForm]);
        }
    }
  
    public edit(data: TareaRiegoModel) {
        //console.log('data',data);
      
        this.router.navigate(['dashboard', 'editar-riego', data.id]);
    }
  
    public duplicate(data: TareaRiegoModel) {
        this.router.navigate(['dashboard', 'duplicar-riego', data.id]);
    }  

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }  

    public override show(modal: string, data: TareaRiegoModel) {
        let x;
        this.formFields.forEach( form => {
            if ( form.field === 'mantenimiento' ){
                if (data.mantenimiento ) {
                    form.label = 'Creación';
                } else {
                    form.label = 'Mantenimiento';
                }
            }
        });
        const trueFields = [
            'mantenimiento',
            'off_on'
        ];
    
        trueFields.forEach( f => {
            data[f] = TRUTHY_VALUES.includes(data[f]?.toString() ?? '');
        });

        this.model = data;

        this.canShow[modal] = true;

        for (x in this.canShow) {
            if (x !== modal && this.canShow[x]) {
                this.canShow[x] = false;
            }
        }
    }  

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if (col.field === 'prioridad') {
            if (value === 'Alta') {
                return {
                    rowStyle: {
                        backgroundColor: '#f44242'
                    },  
                };
            }
            else if (value === 'Realizada'){
                return {
                    rowStyle: {
                        backgroundColor: '#00ff00'
                    },  
                };
            }
        }

        if (col.field === 'trabajadores_nombres') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        if (col.field === 'observaciones') {
            return {
                style: {
                    'max-width': '50px',
                }
            };
        }
        return {};
    }
  
    public globalVisualTransform(value: string, col: ColInterface) {
    

        if (col.field === 'mantenimiento') {
            if ( TRUTHY_VALUES.includes(value) ) {
                return 'Creación';
            } else {
                return 'Mantemiento';
            }
        }
        return value;  
    }
  
    public formatDate(date: string) {
        moment.locale('es');
        return moment(date, 'DD/MM/YYYY').format('LL');
    }

    public filtrarRealizados(value: string, from = 'prioridad') {
        if (from === 'prioridad') {
            this.prioridad.selected = this.prioridad.selected === value ? '' : value;
        } 

        this.filtering.filter();
    }

    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFields,
            this.formFieldsHorarioRiego,
            this.formFieldsHoraRiego,
            this.formFieldsSectores,
            this.formFieldsAspersores,
            this.formFieldsDifusores,
            this.formFieldsArqueta,
            this.formFieldsArquetas,
            this.formFieldsElectrovalvulas9vLatch,
            this.formFieldsElectrovalvulas24v,
            this.formFieldsSolenoides,
            this.formFieldsProgramadorPilas,
            this.formFieldsProgramador220v,
            this.formFieldsCajaConexion,
            this.formFieldsTuberiaGoteo,
            this.formFieldsTuberiaLisa,
            this.formFieldsPilas,
            this.formFieldsTuberiaPE01,
            this.formFieldsTuberiaPE02,
            this.formFieldsTuberiaPVC,
            this.formFieldsRiegoManguera,
            this.formFieldsTrabajadores,
            this.formFieldsOrdenTrabajo,
            this.formFieldsObs,
        ];

        const csvContent = createCsv(arrayFormFields, this.filtering.value, 'observaciones');

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement | null;

        if (csvLink) {
            csvLink.download = 'Riego.csv';
            csvLink.href = encodeURI(csvContent);
            csvLink.click();
        }// if();
    }

    public override updateFooter() {
        this.sum();
    }

    public override sum() {
        this.totalHoras = moment(new Date('October 1, 2014 00:00:00'));
        this.auxTotalHoras = moment(new Date('October 1, 2014 00:00:00'));
        let contador = 0;
        (this.filtering.value || []).forEach((tareas, index ) => {

            if ( tareas.horas_totales != null ) {
   
                if (tareas.horas_totales.length > 0) {
                    contador++;
                    const horaMinuto = tareas.horas_totales.split(':');
                    this.totalHoras = moment(this.totalHoras).add(horaMinuto[0], 'hours').add(horaMinuto[1], 'minutes');
                }
            }

            if ( index + 1 >= (this.filtering.value as TareaRiegoModel[]).length ){
  
                let minutos = moment(this.totalHoras).format('mm');

                let differencia = moment(this.totalHoras).diff(this.auxTotalHoras);
      
                if ( minutos.toString().length === 0) {
                    minutos = '00';
                }

                if ( minutos.toString().length === 1) {
                    minutos = '0' + minutos.toString();
                }
                differencia = differencia / (1000 * 60 * 60);
                differencia = Math.abs(Math.floor(differencia));

                this.totalHoras = differencia + ':' + minutos;
      
                if ( contador === 0){
                    this.totalHoras = '00:00';
                }
            }

        });

        if ( contador === 0){
            this.totalHoras = '00:00';
        }
        this.filtering.filter();
    }

    getFechaInicio( event: string) {
        this.fecha.inicio = event;
        this.filtering.filter();
        this.updateFooter();
  
    }
    getFechaFin( event: string) {
        this.fecha.fin = event;
        this.filtering.filter();
        this.updateFooter();
    }

    ngAfterViewInit() {
        this.tareasApi.mtoRiego.GET.response(() => {
            this.updateFooter();
            this.sum();
        });
    }

}
