import { createCsv } from '../../../../common/createCsv';
import { Component } from '@angular/core';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {Router} from '@angular/router';
import {TareasApiService} from '../../../../service/api/tareas-api.service';
import {Utils} from '../../../../common/utils';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {Filtering} from '../../../../service/filtering/filtering';
import {BaseView} from '../../../base-view';
import { environment } from '../../../../../environments/environment';
import { StorageManager } from '../../../../common/storage-manager.class';
import moment, { Moment } from 'moment';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { MessageService } from 'primeng/api';

import type { OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TareaFertiModel } from 'src/app/models/tareas/tarea-ferti.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';

moment.locale('es');
@Component({
    selector: 'app-fito',
    templateUrl: './fertilizantes.component.html',
    styleUrls: ['./fertilizantes.component.scss']
})
export class FertilizantesComponent extends BaseView<TareaFertiModel> implements OnInit, OnDestroy, AfterViewInit {
    public years = [
        {label: '...', value: 0},
    ];

    public rolUser = StorageManager.getUser().rol;
    public hiddenButtonDelete = false;
    public showButtonDuplicate = true;
    public canAddTareas = environment.features.canAddTareas;

    public selectedYears = 0;
    public modalShowCampaign  = false;
    public showCampaign = environment.features.showTareasFertilizantesCampaign;
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public serverUrl = environment.serverUrl;
    public logoUrl = environment.logoUrl;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
        (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));
    
    public canDuplicateTareas = environment.features.canDuplicateTareas;
    public showFertilizantesFormShort = environment.features.showFertilizantesFormShort;
    public showTareasFertiObservaciones = environment.features.showTareasFertiObservaciones;
    public showTareasFertiDias = environment.features.showTareasFertiDias;

    public showRecetaFertiPascualCabedo = environment.features.showRecetaFertiPascualCabedo;
    public showRecetaFertiAgrogest = environment.features.showRecetaFertiAgrogest;
    public showTareasFertiGramos = environment.features.showTareasFertiGramos;
    public showEnviarWhatsapp = environment.features.showEnviarWhatsapp;
    public showEnviarCorreo = environment.features.showEnviarCorreo;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public showFertilizantesSectores = environment.features.showFertilizantesSectores;
    public showFertilizantesParcelas = environment.features.showFertilizantesParcelas;
    public showFertiNombreCuba = environment.features.showFertiNombreCuba;
    public showTareasFertiConsumoAgua = environment.features.showTareasFertiConsumoAgua;
    public clienteName = environment.features.clienteName;
    public showFertilizantesNPK = environment.features.showFertilizantesNPK;
    public showFertilizantesCa = environment.features.showFertilizantesCa;
    public showFertilizantesS = environment.features.showFertilizantesS;
    public showFertilizantesMg = environment.features.showFertilizantesMg;
    public showFertilizantesAlbaranFactura = environment.features.showFertilizantesAlbaranFactura;
    public showTipoFertilizacion = environment.features.showTareasFertilizantesTipoFertilizacion;
    public showTareasProcura = environment.features.showTareasProcura;   
    public textoSuperficie = environment.features.textoSuperficie;
    public showClientesNotas = environment.features.showClientesNotas;
    public showTareasBioExportCsv = environment.features.showTareasBioExportCsv;
    public betweenDates = environment.features.showTareasFitoBetweenDates;

    public isGeneratingCanvas = false;

    public showPrescripcion = false;
    public filter: Filtering<TareaFertiModel> = new Filtering<TareaFertiModel>();
    public getRequest: RequestHandler<TareaFertiModel[]> = this.tareasApi.fertilizantes.GET;
    public deleteRequest: RequestHandler<TareaFertiModel> = this.tareasApi.fertilizantes.DELETE;
    public pathToForm = 'crear-fertilizante-tarea';
    public menuItemId = 'fertilizantes';
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public btnAnadir = 'Añadir Fertilizante';

    public receta: TareaFertiModel = {};
    public showReceta = false;

    public override canShow: Record<string, boolean> = {};
    public override model: TareaFertiModel = {};
    public oneToThirtySix = [
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4,
        1, 2, 3, 4
    ];

    public showTareasPrioridad = environment.features.showTareasPrioridad;

    public formFields: ItemInterface<object>[] = [
        {
            field: 'finca', 
            name: this.fincaName, 
            label: this.fincaName, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'sectores_nombres', 
            name: this.sectoresName, 
            label: this.sectoresName, 
            inputType: {type: InputType.TEXTAREA},
            visible: this.showFertilizantesSectores},
        {
            field: 'parcelas_nombres', 
            name: this.parcelasName, 
            label: this.parcelasName, 
            inputType: {type: InputType.TEXTAREA},
            visible: this.showFertilizantesParcelas},
        {
            field: 'parcelas_sup', 
            name: 'Superficie Cultivada', 
            label: 'Superficie Cultivada', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'pyf' },
        {
            field: 'parcelas_superficie', 
            name: 'Superficie Cultivada', 
            label: 'Superficie Cultivada', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'esparragosgranada'
        },
        {
            field: 'facturado', 
            name: 'Facturado', 
            label: 'Facturado', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.appName === 'agrosalvi'
        },
        {
            field: 'albaran_factura', 
            name: this.appName === 'agrosalvi' ? 'Factura/Albarán' : 'Albarán/factura', 
            label: this.appName === 'agrosalvi' ? 'Factura/Albarán' : 'Albarán/factura', 
            inputType: {type: InputType.TEXT} 
        },
        {
            field: 'fecha_inicio', 
            name: 'Fecha Inicio', 
            label: 'Fecha Inicio', 
            visible: !environment.features.showTareasMto, 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'fecha_fin', 
            name: environment.features.showTareasMto ? 'Fecha' : 'Fecha Fin', 
            label: environment.features.showTareasMto ? 'Fecha' : 'Fecha Fin', inputType: {type: InputType.TEXT}
        },
        {
            field: 'hora_inicio', 
            name: 'Hora Inicio', 
            label: 'Hora Inicio', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.timeStartStopTareas || environment.features.showTareasFertiHoras
        },
        {
            field: 'hora_fin', 
            name: 'Hora Fin', 
            label: 'Hora Fin', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.timeStartStopTareas || environment.features.showTareasFertiHoras},        
        {
            field: 'horas_totales', 
            name: 'Horas', 
            label: 'Horas', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.showTareasMto || environment.features.tareasHorasTotales },
        { 
            field: 'horas_totales', 
            name: 'Horas', 
            label: 'Horas', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'fotoagricultura' },
        {
            field: 'tipo_fertilizacion', 
            name: 'Tipo Fertilización',
            label: 'Tipo Fertilización', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'prioridad', 
            name: 'Prioridad/Realizada',
            label: 'Prioridad/Realizada', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'ava'
        },
        {
            field: 'procura',
            name: 'Procura',
            label: 'Procura',
            inputType: { type: InputType.TEXT },
            visible: this.showTareasProcura
        },        
    
        {
            field: 'horas_dia', 
            name: 'Horas/día', 
            label: 'Horas/día', 
            inputType: {type: InputType.TEXT}, 
            visible: !this.showFertilizantesFormShort
        },
        {
            field: 'numero_dias', 
            name: 'Días/semana', 
            label: 'Días/semana', 
            inputType: {type: InputType.TEXT}, 
            visible: !this.showFertilizantesFormShort
        }, 
        {
            field: 'dias_semana',
            name: 'Días',
            label: 'Días',
            inputType: {type: InputType.TEXT},
            visible: this.showTareasFertiDias && !this.showFertilizantesFormShort
        },
        {
            field: 'nombre_cuba', 
            name: 'Nombre/Cuba', 
            label: 'Nombre/Cuba', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showFertiNombreCuba
        },
        {
            field: 'consumo_agua', 
            name: 'Consumo Agua m³', 
            label: 'Consumo Agua m³', 
            inputType: {type: InputType.TEXT}, 
            visible: this.showTareasFertiConsumoAgua
        },
        {
            field: 'notas_cliente', 
            name: 'Notas cliente', 
            label: 'Notas cliente', 
            inputType: {type: InputType.TEXTAREA}, 
            visible: this.showClientesNotas
        },
        {
            field: 'ids_trabajadores', 
            name: 'Profesionales', 
            label: 'Profesionales', 
            inputType: {type: InputType.TEXT}, 
            visible: environment.features.showTareasMto || this.appName === 'fotoagricultura',
        },
        {
            field: 'aplicado',
            name: 'Aplicado', 
            label: 'Aplicado', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: environment.features.showTareasMto || environment.features.showTareasAplicados
        },
        {
            field: 'grupo', 
            name: 'Grupo', 
            label: 'Grupo', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'tipo_riego',
            name: 'Tipo Riego', 
            label: 'Tipo Riego',
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'produccion_estimada', 
            name: 'Producción Estimada', 
            label: 'Producción Estimada', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'necesidad_maxima', 
            name: 'Necesidad Máxima', 
            label: 'Necesidad Máxima', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'aporte', 
            name: 'Aporte', 
            label: 'Aporte', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'cosecha_obtenida', 
            name: 'Cosecha Obtenida', 
            label: 'Cosecha Obtenida', 
            inputType: {type: InputType.TEXT}, 
            visible: this.appName === 'agropecuariaramirogruas'
        },
        {
            field: 'estado_tarea', 
            name: 'Estado de la Tarea',
            label: 'Estado de la Tarea', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
        {
            field: 'empresa_pagadora',
            name: 'Empresa Pagadora',
            label: 'Empresa Pagadora', 
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
        {
            field: 'recomendaciones_riego',
            name: 'Recomendaciones de Riego',
            label: 'Recomendaciones de Riego',
            inputType: {type: InputType.TEXTAREA},
            visible: this.appName === 'agufert'
        },
        {
            field: 'observaciones',
            name: 'Observaciones',
            label: 'Observaciones',
            inputType: {type: InputType.TEXTAREA},
            visible: this.showTareasFertiObservaciones
        }
    ];

    public formFieldsPac: ItemInterface<object>[] = [
        {
            field: 'fecha_riego',
            name: 'Fecha Riego',
            label: 'Fecha Riego',
            inputType: {type: InputType.TEXT},
        },
        { 
            field: 'sistema_riego',
            name: 'Sistema de Riegos', 
            label: 'Sistema de Riegos', 
            inputType: {type: InputType.TEXT}
        },
        {
            field: 'declara_buenas_practicas',
            name: 'Declara Buenas Prácticas',
            label: 'Buenas Prácticas',
            inputType: { type: InputType.RO_SWITCH}
        },
        {
            field: 'codigos_buena_practica',
            name: 'Buenas Prácticas',
            label: 'Buenas Prácticas:',
            inputType: {type: InputType.TEXT},
        },
        {
            field: 'dosis_riego',
            name: 'Dosis de Riego',
            label: 'Dosis',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'medida_riego',
            name: 'Medida de Riego',
            label: 'Medida',
            inputType: { type: InputType.TEXT },
        },
        {
            field: 'n_agua',
            name: 'N nítrico del agua',
            label: 'N nítrico del agua',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'medida_n_agua',
            name: 'Medida N en Agua',
            label: 'Medida',
            inputType: { type: InputType.TEXT },
        },
        {
            field: 'p205',
            name: 'P205 soluble en agua',
            label: 'P205 soluble en agua',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'medida_p205',
            name: 'Medida P205',
            label: 'Medida',
            inputType: { type: InputType.TEXT },
        },
        {
            field: 'id_maquinaria',
            name: 'Equipo Aplicación',
            label: 'Equipo Aplicación',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'aplicacion_residuos_r10',
            name: 'Aplicación de residuos R10',
            label: 'Aplicación de residuos R10',
            inputType: { type: InputType.TEXT },
        },
        {
            field: 'documento_aplicacion',
            name: 'Documento de Aplicación',
            label: 'Documento de Aplicación',
            inputType: { type: InputType.TEXT }
        },        
    ];

    public formFieldsAbonado: ItemInterface<object>[] = [
        {
            field: 'plan_abonado',
            name: 'Plan Abonado',
            label: 'Plan Abonado',
            inputType: { type: InputType.RO_SWITCH },
        },
        {
            field: 'necesidad_uf_n',
            name: 'Necesidad UF N (Kg/Ha)',
            label: 'Necesidad UF N (Kg/Ha)',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'necesidad_uf_p205',
            name: 'Necesidad UF P205 (Kg/Ha)',
            label: 'Necesidad UF P205 (Kg/Ha)',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'necesidad_uf_k20',
            name: 'Necesidad UF K20 (Kg/Ha)',
            label: 'Necesidad UF K20 (Kg/Ha)',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'objetivo_produccion',
            name: 'Objetivo Producción (Kg/Ha)',
            label: 'Objetivo Producción (Kg/Ha)',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'id_cultivos',
            name: 'Producto Precedente',
            label: 'Producto Precedente',
            inputType: { type: InputType.TEXT },
        },
        {
            field: 'uso_herramienta',
            name: 'Uso de Herramienta',
            label: 'Uso de Herramienta',
            inputType: { type: InputType.TEXT },
        },
        {
            field: 'codigo_regfer',
            name: 'Código REGFER',
            label: 'Código REGFER',
            inputType: { type: InputType.TEXT },
        },
        {
            field: 'fecha_asesoramiento',
            name: 'Fecha Asesoramiento',
            label: 'Fecha Asesoramiento',
            inputType: { type: InputType.TEXT },
        }
    ]; 

    public formFieldsProductos: ItemInterface<object>[] = [
        { 
            field: 'producto1', 
            name: 'Producto 1', 
            label: 'Producto', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'dosis1', 
            name: 'Dosis 1', 
            label: 'Kg Totales', 
            inputType: { type: InputType.TEXT } 
        },
        {
            field: 'gramos1', 
            name: 'Peso 1', 
            label: (this.appName === 'prosanzcu') ? 'Kg/' + this.textoSuperficie : 
                ['abonosfolques', 'pyf'].includes(this.appName) ? 'Gr./árbol' : 
                    this.appName === 'ferticinca' ? '(Dosis/' + this.textoSuperficie + ')' : 
                        'Gr./árbol (dosis/' + this.textoSuperficie + ')',
            inputType: { type: InputType.TEXT }, visible: this.showTareasFertiGramos
        },
        {
            field: 'kg_ha1', 
            name: 'Kg/' + this.textoSuperficie + '1', 
            label: 'Kg/' + this.textoSuperficie,
            visible: (this.appName === 'pyf'),
            inputType: { type: InputType.ONLY_TEXT }
        },
        { 
            field: 'producto2', 
            name: 'Producto 2', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'dosis2', 
            name: 'Dosis 2', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'gramos2', 
            name: 'Peso 2', 
            inputType: { type: InputType.TEXT }, 
            visible: this.showTareasFertiGramos 
        },
        {
            field: 'kg_ha2',
            name: 'Kg/' + this.textoSuperficie + '2',
            visible: (this.appName === 'pyf'),
            inputType: { type: InputType.ONLY_TEXT }
        },
        { 
            field: 'producto3', 
            name: 'Producto 3', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'dosis3', 
            name: 'Dosis 3', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'gramos3', 
            name: 'Peso 3', 
            inputType: { type: InputType.TEXT }, 
            visible: this.showTareasFertiGramos 
        },
        {
            field: 'kg_ha3',
            name: 'Kg/' + this.textoSuperficie + '3',
            visible: (this.appName === 'pyf'),
            inputType: { type: InputType.ONLY_TEXT }
        },
        { 
            field: 'producto4', 
            name: 'Producto 4', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'dosis4', 
            name: 'Dosis 4', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'gramos4', 
            name: 'Peso 4', 
            inputType: { type: InputType.TEXT }, 
            visible: this.showTareasFertiGramos 
        },
        {
            field: 'kg_ha4',
            name: 'Kg/' + this.textoSuperficie + '4',
            visible: (this.appName === 'pyf'),
            inputType: { type: InputType.ONLY_TEXT }
        },
        { 
            field: 'producto5', 
            name: 'Producto 5', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'dosis5', 
            name: 'Dosis 5', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'gramos5', 
            name: 'Peso 5', 
            inputType: { type: InputType.TEXT }, 
            visible: this.showTareasFertiGramos 
        },
        {
            field: 'kg_ha5',
            name: 'Kg/' + this.textoSuperficie + '5',
            visible: (this.appName === 'pyf'),
            inputType: { type: InputType.ONLY_TEXT }
        },
        { 
            field: 'producto6', 
            name: 'Producto 6', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'dosis6', 
            name: 'Dosis 6', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'gramos6', 
            name: 'Peso 6', 
            inputType: { type: InputType.TEXT }, 
            visible: this.showTareasFertiGramos 
        },
        {
            field: 'kg_ha6',
            name: 'Kg/' + this.textoSuperficie + '6',
            visible: (this.appName === 'pyf'),
            inputType: { type: InputType.ONLY_TEXT }
        },
        { 
            field: 'producto7', 
            name: 'Producto 7', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'dosis7', 
            name: 'Dosis 7', 
            inputType: { type: InputType.TEXT } 
        },
        { 
            field: 'gramos7', 
            name: 'Peso 7', 
            inputType: { type: InputType.TEXT }, 
            visible: this.showTareasFertiGramos 
        },
        {
            field: 'kg_ha7',
            name: 'Kg/' + this.textoSuperficie + '7',
            visible: (this.appName === 'pyf'),
            inputType: { type: InputType.ONLY_TEXT }
        },
        { 
            field: 'prioridad', 
            name: 'Prioridad/Realizada', 
            label: 'Prioridad/Realizada', 
            inputType: { type: InputType.TEXT }, 
            visible: this.applicationType !== 'cropgest' 
        },
        { 
            field: 'nombres_asignados', 
            name: 'Trabajados Asignados', 
            label: 'Trabajadores asignados', 
            inputType: { type: InputType.TEXT }, 
            visible: environment.features.showAssignUserTasksFertilizante || environment.features.showAssignUserInAllTasks 
        },

    ];

    public formFieldsOrdenTrabajo: ItemInterface<object>[] = 
        [  
            {
                field: 'orden_trabajo', 
                name: 'Orden de trabajo', 
                label: 'Orden de trabajo', 
                inputType: {type: InputType.TEXTAREA}
            }
        ];

    public formFieldsObs: ItemInterface<object>[] = 
        [  
            {
                field: 'observaciones', 
                name: 'Observaciones', 
                label: 'Observaciones', 
                inputType: {type: InputType.TEXTAREA}
            },
        ];

    
    public cols: ColInterface[] = [
        { 
            field: 'fecha_inicio', 
            header: 'F.\xa0Inicio', 
            visible: !environment.features.showTareasMto 
        },
        { 
            field: 'fecha_fin', 
            header: 'F.\xa0Fin' 
        },
        { 
            field: 'cliente_nombre', 
            header: this.clienteName, 
            filter: '', 
            visible: this.hasClients && this.userCanSeeClientes && !environment.features.showTareasMto 
        },
        { 
            field: 'finca', 
            header: this.fincaName 
        },
        { 
            field: 'sectores_nombres', 
            header: this.sectoresName, 
            visible: this.showFertilizantesSectores && !environment.features.showTareasMto 
        },
        { 
            field: 'trabajadores_nombres', 
            header: 'Empleado', 
            visible: environment.features.showTareasMto || environment.features.showTareasFertiHoras 
        },
        { 
            field: 'hora_inicio', 
            header: 'Hora Inicio', 
            visible: environment.features.showTareasMto || environment.features.showTareasFertiHoras 
        },
        { 
            field: 'hora_fin', 
            header: 'Hora Fin', 
            visible: environment.features.showTareasMto 
        },
        { 
            field: 'horas_totales', 
            header: 'Horas', 
            filter: '', 
            visible: environment.features.showTareasMto || environment.features.tareasHorasTotales 
        },
        { 
            field: 'parcelas_nombres', 
            header: this.parcelasName, 
            visible: !environment.features.showTareasMto && this.showFertilizantesParcelas 
        },
        { 
            field: 'nombre_cuba', 
            header: 'Cuba', 
            visible: this.showFertiNombreCuba 
        },
        { 
            field: 'producto1', 
            header: 'Prod.\xa01' 
        },
        { 
            field: 'dosis1', 
            header: 'Cant.\xa01', 
            width: '70px' 
        },
        { 
            field: 'n', 
            header: 'N%', 
            width: '80px', 
            footer: '', 
            visible: this.showFertilizantesNPK 
        },
        { 
            field: 'p', 
            header: 'P%', 
            width: '80px', 
            footer: '', 
            visible: this.showFertilizantesNPK 
        },
        { 
            field: 'k', 
            header: 'K%', 
            width: '80px', 
            footer: '', 
            visible: this.showFertilizantesNPK 
        },
        { 
            field: 'ca', 
            header: 'Ca%', 
            width: '80px', 
            footer: '', 
            visible: this.showFertilizantesCa 
        },
        { 
            field: 's', 
            header: 'S%', 
            width: '80px', 
            footer: '', 
            visible: this.showFertilizantesS 
        },
        { 
            field: 'mg', 
            header: 'Mg%', 
            width: '80px', 
            footer: '', 
            visible: this.showFertilizantesMg 
        },
        { 
            field: 'tipo_fertilizacion', 
            header: 'Tipo\xa0Fert.', 
            visible: this.showTipoFertilizacion 
        },
        { 
            field: 'prioridad', 
            header: 'Prioridad', 
            filter: '', 
            visible: this.appName === 'ava' 
        },
        { 
            field: 'facturado', 
            header: 'Facturado', 
            filter: '', 
            visible: this.appName === 'agrosalvi' 
        },
        { 
            field: 'nombres_asignados', 
            header: 'Asignado', 
            filter: '', 
            visible: environment.features.showAssignUserTasksFertilizante || environment.features.showAssignUserInAllTasks 
        }

    ];

    public distributionColInfoProduct = 3; 
    public distributionOrderPac = [4, 6, 4, 2, 2];
    public aplicado = {
        selected: ''
    };
    public validado = {
        selected: ''
    };
    public prioridad = {
        selected: ''
    };
    public totalHoras: Moment | string = '00:00';
    public auxTotalHoras: Moment | string = '00:00';
    public fecha: {inicio: string; fin: string} = {
        inicio: '',
        fin: '',
    };

    constructor(public tareasApi: TareasApiService,
        private dashboardEvents: DashboardService,
        public dashboard: DashboardService,
        private router: Router,
        private messageService: MessageService) {
        super(dashboardEvents, tareasApi.fertilizantes.GET);
    }

    ngOnInit() {

        if (environment.features.showTareasMto || environment.features.showTareasAplicados) {

            this.aplicado.selected = 'no-aplicados';

            if (this.appName === 'donjardin' && this.rolUser !== 'admin'){
                this.hiddenButtonDelete = true;
            }
            
            
            this.filter.addFilter((copy: TareaFertiModel[] | void) => (copy as TareaFertiModel[]).filter(row => {
                switch (this.aplicado.selected) {
                case 'aplicados':
                    return [true, '1', 1].includes(row.aplicado ?? false);
                case 'no-aplicados':
                    return [null, undefined, '0', 0].includes(row.aplicado?.toString());
                default:
                    return true;
                }
            }));
        }

        if (environment.features.showTareasPrioridad){
            this.prioridad.selected = 'No-Realizada';

            this.filter.addFilter((copy: TareaFertiModel[] | void) => (copy as TareaFertiModel[]).filter(row => {
                //console.log('row', row);
                switch (this.prioridad.selected) {
                case 'Realizada':
                    return ['Realizada'].includes(row.prioridad ?? '');
                case 'No-Realizada':
                    return !['Realizada'].includes(row.prioridad ?? '');
                default:
                    return true;
                }
            }));
        }

        this.selectNumColumnsInfoDelete();
        if (this.showCampaign) {

            this.fillCampaign();
            this.filter.addFilter((copy: TareaFertiModel[] | void) => (copy as TareaFertiModel[]).filter(row => {
                if (this.selectedYears === 0) {
                    return true;
                } else {
                    return this.compareDates(row);
                }
            }));
        }

        this.tareasApi.enviarRecetaCaptura.response(() => {
            if (!this.tareasApi.enviarRecetaCaptura.hasError && this.showReceta) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Email enviado',
                    detail: 'Se ha enviado el impreso de la receta correctamente.'
                });
                this.showReceta = false;
            }
        });

        if (this.showRecetaFertiAgrogest || this.showRecetaFertiPascualCabedo) {
            this.showPrescripcion = true;
        }

        this.dashboardEvents.setSubmenuItem('fertilizantes');
        this.getRequest.response(() => {
            setTimeout(() => {
                this.updateFooter();
            }, 1);
        });

        if (environment.features.showTareasMto) {
            /*** 
            *  En el caso de que el usuario no se admin,
            * filtrará por su id de usuario que coincide,
            *  con el id trabajador   
            */
            if (StorageManager.getUser().rol !== 'admin') {
                this.filter.addFilter((copy: TareaFertiModel[] | void) => (copy as TareaFertiModel[]).filter(row => {

                    if (row.ids_trabajadores != null) {

                        const idTrabajador = StorageManager.getUser().id;
                        const listaTrabajadores = (row.ids_trabajadores as string).split(';');
                        if (listaTrabajadores.includes(idTrabajador)) {
                            return true;
                        }

                    }
                    return false;
                }));
            }

            /*** 
            *  Fin de filtrar por id_usuario = trabajador,
            *   
           */
        }

        /**
             *  Activado la asignacion de usuarios en la tabla tareas
             *  Filtraremos las tareas si en caso de que el usuario se encuentre como asignado en la tarea
             *  Para su funcionamiento la referencia se obtiene de una recopilacion en php 
             */
        // if (environment.features.showAssignUserTasksFertilizante || environment.features.showAssignUserInAllTasks) {
        if (this.appName === 'ava'){
            /** Solo el que tiene el rol de admin podra ver todas las tareas */
            if (StorageManager.getUser().rol !== 'admin') {
                this.filter.addFilter((copy: TareaFertiModel[] | void) => (copy as TareaFertiModel[]).filter(row => {
                    if (row.referencia_usuario != null) {

                        const idTrabajador = StorageManager.getUser().id;
                        const listaTrabajadores = (row.referencia_usuario as string).split(';');
                        if (listaTrabajadores.includes(idTrabajador)) {
                            return true;
                        }

                    }
                    return false;
                }));
            }
        }// Fin filtro asignados
        
        if (this.betweenDates) {
            const nameDate = 'fecha_fin';
            this.filter.addFilter((copy: TareaFertiModel[] | void) => (copy as TareaFertiModel[]).filter(row => {
                if (this.fecha.inicio && this.fecha.fin) {
                    if (row[nameDate] == null) {
                        return false;
                    } else {
                        const arrayFecha = row[nameDate].toString().split('/');
                        const fechaAux = arrayFecha[2] + '-' + arrayFecha[1] + '-' + arrayFecha[0];

                        if (
                            (moment(moment(this.fecha.inicio).format('YYYY-MM-DD')).diff(fechaAux) <= 0) && 
                            (moment(moment(this.fecha.fin).format('YYYY-MM-DD')).diff(fechaAux) >= 0)
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }

                } else {
                    return true;
                }
            }));
        }
    }

    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {
        if ( col.field === 'horas_totales') {
            return {
                textAlign: 'center'
            };
        }
      
        if (Utils.isNumber(value) && col.field !== 'finca' && col.field !== 'facturado') {
            return {
                textAlign: 'right',
            };
        }
        if (col.field === 'nombre_cuba') {
            return {
                textAlign: 'center',
            };
        }
        if (col.field === 'finca') {
            return {
                textAlign: 'left',
            };
        }

        if (col.field === 'prioridad') {
            if (value === 'Alta') {
                return {
                    rowStyle: {
                        backgroundColor: '#f44242'
                    },  
                };
            }
            else if (value === 'Realizada'){
                return {
                    rowStyle: {
                        backgroundColor: '#00ff00'
                    },  
                };
            }
        }

        return {};
    }

    public globalVisualTransform(value: string, col: ColInterface, row: TareaFertiModel) {
        
        if (Utils.isNumber(value) && col.field !== 'finca' && col.field !== 'facturado') {
            // formato decimal y millar
            return Utils.decimalFormat(value, 2, '.', ',', 3);
        }

        if (col.field === 'parcelas_nombres') {
            const str = Utils.replaceAll(Utils.replaceAll((row[col.field]?.toString() || ''), '- ', ''), '\n', '\n, ');
            value = str.substring(0, str.length - 2);
            return (value.length > 50) ? value.slice(0, 50 - 1) + '…' : value;
        }

        if (col.field === 'sectores_nombres') {
            const str = Utils.replaceAll(Utils.replaceAll((row[col.field]?.toString() || '' ), '- ', ''), '\n', '\n, ');
            return str.substring(0, str.length - 2);
        }

        if (col.field === 'facturado') {
            switch (value) {
            case '0':
                return 'No';
            case '1':
                return 'Sí';
            default:
                return 'No';
            }
        }

        return value;
    }

    public filtrarRealizados(value: string, from = 'prioridad') {
        if (from === 'prioridad') {
            this.prioridad.selected = this.prioridad.selected === value ? '' : value;
        }
    
        this.filter.filter();
        this.updateFooter();
    }

    public addFertilizante() {
        if (!StorageManager.getClient() && this.userCanSeeClientes) {
            this.dashboard.openClientDialog();
        } else {
            this.router.navigate(['dashboard', 'crear-fertilizante-tarea']);
        }
    }

    public add() {
        this.router.navigate(['dashboard', this.pathToForm]);
    }

    public edit(data: TareaFertiModel) {
        this.router.navigate(['dashboard', 'editar-fertilizante-tarea', data.id]);
    }

    public duplicate(data: TareaFertiModel) {
        this.router.navigate(['dashboard', 'duplicar-fertilizante-tarea', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public override async show(modal: string, data: TareaFertiModel) {
       
        if ( environment.features.showTareasMto ) {
            data.aplicado = [1, '1', true, 'true'].includes(this.model.aplicado ?? false);
        }
        this.model = data;
       
      

        this.formatDiasSemana();
        this.formatParcelas();

        super.show(modal, this.model);

        for (let i = 1; i <= 7; i++) {
            if (this.model[`dosis${i}`] === 0) {
                this.model[`producto${i}`] = null;
                this.model[`dosis${i}`] = null;
            }
        }
        await this.sumSupCultivada(data.parcelas ?? []);
        if ( this.appName === 'pyf' ) {
            if (  this.model['superficie_total'] != null ) {
                this.divideKgByHa(this.model.superficie_total);
            }
        }
    }

    public formatParcelas() {
        this.model.parcelas_superficie = 0;
        this.model.parcelas?.forEach((parcela: ParcelasModel) => {
            if (parcela && this.model.parcelas_superficie !== undefined) {
                this.model.parcelas_superficie += parseFloat(parcela.superficie_cultivada || '0.00').toString();
            }
        });
        this.model.parcelas_superficie = +this.model.parcelas_superficie.toFixed(2);
    }

    public generarTablaAbonado() {
        this.show('tablaAbonado', {});
    }

    public override sum(field: string) {
        let sum = 0;

        (this.filter.value || []).forEach(it => {
            sum += +(it[field] || 0);
        });

        this.cols.forEach(it => {
            if (it.field === field) {
                it.footer = Utils.decimalFormat(sum + '', 2, '.', ',', 3);
            }
        });
    }

    public override updateFooter() {
        ['n', 'p', 'k', 'ca'].forEach(it => this.sum(it));
        if ( this.betweenDates ) {
            this.sumHours();
        }
    }

    public formatDiasSemana() {
        const dias = (this.model.dias_semana || '').split(';');
        const arrDias: string[] = [];
        this.model.dias_semana_formatted = '';

        dias.forEach((dia: string) => {
            if (dia === 'L') { arrDias.push('Lunes'); }
            if (dia === 'M') { arrDias.push('Martes'); }
            if (dia === 'X') { arrDias.push('Miércoles'); }
            if (dia === 'J') { arrDias.push('Jueves'); }
            if (dia === 'V') { arrDias.push('Viernes'); }
            if (dia === 'S') { arrDias.push('Sábado'); }
            if (dia === 'D') { arrDias.push('Domingo'); }
        });

        arrDias.forEach((dia, index) => {
            this.model.dias_semana_formatted += dia;
            if (index !== arrDias.length - 1) {
                this.model.dias_semana_formatted += ', ';
            }
        });
    }

    public generarReceta(data: TareaFertiModel) {
        this.tareasApi.enviarRecetaCaptura.hasError = false;
        this.tareasApi.enviarRecetaCaptura['error'] = null;

        this.model = data;
        this.formatDiasSemana();
        this.receta = this.model;
        
        this.showReceta = true;
    }

    public enviarCorreo() {

        this.isGeneratingCanvas = true;
        const config = this.getPrintConfig();

        html2canvas(
            document.getElementById('html2canvasDiv') ?? new HTMLElement(), 
            { scale: config.canvasScale, logging: false }
        ).then(canvas => {
            this.isGeneratingCanvas = false;

            const pdf = new jsPDF(config.orientation, 'mm', 'a4');
            const data = canvas.toDataURL('image/png');
            
            if (canvas.width > canvas.height) {
                pdf.addImage(data, 'png', 0, 0, config.width, (canvas.height / (canvas.width / config.width)), 'alias', 'FAST');
            } else {
                pdf.addImage(data, 'png', 0, 0, (canvas.width / (canvas.height / config.height)), config.height, 'alias', 'FAST');
            }

            const adjunto = encodeURIComponent(window.btoa(pdf.output()));

            let email = '';
            if (this.receta.finca_email) {
                email = this.receta.finca_email;
            } else if (this.receta.cliente_email) {
                email = this.receta.cliente_email;
            } else {
                email = '';
            }
      
            if (email !== '') {
                this.tareasApi.enviarRecetaCaptura.perform({
                    email,
                    generado: adjunto,
                    finca: this.receta.finca,
                    tratamiento: this.receta.tratamiento_nombre ? this.receta.tratamiento_nombre : 'fertilizante',
                } as never);
              
            } else {
                this.tareasApi.enviarRecetaCaptura.hasError = true;
                this.tareasApi.enviarRecetaCaptura['error'] = 'El cliente o finca no tiene un email asociado';
            }
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        ).then(() => {
            console.log('Adjuntos generados.');
            return;
        }, error => {
            console.log(error);
            this.tareasApi.enviarRecetaCaptura.hasError = true;
            this.tareasApi.enviarRecetaCaptura['error'] = 'Error al enviar email';
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }
    
    public enviarWhatsapp() {
        window.open('https://web.whatsapp.com/', '_blank');
    }


    public imprimirReceta() {
        this.isGeneratingCanvas = true;
        const config = this.getPrintConfig();

        html2canvas(
            document.getElementById('html2canvasDiv') ?? new HTMLElement(), 
            { scale: config.canvasScale, logging: false }
        ).then(canvas => {
            this.isGeneratingCanvas = false;
            const pdf = new jsPDF(config.orientation, 'mm', 'a4');
            const data = canvas.toDataURL('image/png');
            
            if (this.appName === 'esparragosgranada') {

                pdf.addImage(data, 'png', 0, 0, config.width, 180 , 'alias', 'FAST');  

            } else {

                if (canvas.width > canvas.height) {
                    pdf.addImage(data, 'png', 0, 0, config.width, (canvas.height / (canvas.width / config.width)) , 'alias', 'FAST');  
                } else {
                    pdf.addImage(data, 'png', 0, 0, (canvas.width / (canvas.height / config.height)), config.height , 'alias', 'FAST');
                }
            }

            pdf.save(
                (this.receta.cliente_nombre || '.').replace('.', '')
                + ' - ' +
                (this.receta.finca || '.').replace('.', '')
            );
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }

    
    public getPrintConfig() {
        const config: {canvasScale: number; orientation: 'p'|'l'; width: number; height: number} = {
            canvasScale: 2,
            orientation: 'l',
            width: 290,
            height: 210
        };

        switch (this.appName) {
        case 'pascualcabedo':
            config.orientation = 'p';
            config.width = 210;
            config.height = 290;
            config.canvasScale = 0.86;
            break;
        case 'armentia':
            config.width = 240;
            config.height = 250;
            break;                
        default:
            break;
        }


        return config;
    }

    public override hide(modal: string) {
        this.model = {};
        this.canShow[modal] = false;
        this.deleteRequest.reset();
    }

    public override ngOnDestroy() {  
        Utils.unsuscribe([
            this.tareasApi.fertilizantes.GET
        ]);
    }

    
    modalCampaign() {
        this.modalShowCampaign = true;
    }

    closeModalShowCampaign() {
        this.modalShowCampaign = false;
    }

    selectedCampaign(){
        this.filter.filter();
        this.updateFooter();
    }

    compareDates( row: TareaFertiModel ) {
        if ( row.fecha_inicio == null || row.fecha_inicio.length === 0 ) {
            return false;
        }
        
        const auxFecha = row.fecha_inicio.split('/');
        const nuevaFecha = moment(auxFecha[2] + '-' + auxFecha[1] + '-' + auxFecha[0] );
        const fechaComparar = moment('20' + (this.selectedYears).toString() + '-07' + '-01' );
        const fechaMax = moment('20' + (this.selectedYears + 1 ).toString() + '-06' + '-30' );

        if ( (nuevaFecha > fechaComparar) && (nuevaFecha < fechaMax) ){
            return true;
        } else {
            return false;
        }
    }
    
    /**
     *  Rellena el listado de campañas
     */
    fillCampaign () {
        const fechaActual = new Date().getFullYear();
        let fechaDosDigitos = 0;
        
        for (let index = 2020; index <= fechaActual; index++) {
            fechaDosDigitos = parseInt( (index.toString()).substring(2, 4) , 10);
            this.years.push({ 
                label: environment.features.applicationType === 'cropgest' ? index.toString() : 
                    (index.toString() + '-' + (fechaDosDigitos + 1 ).toString()), value: fechaDosDigitos 
            });
            
        }
    }

    /**
     *  Calcular superfice cultivada total
     * 
     */

    public sumSupCultivada( parcelas: ParcelasModel[] ) {
        return new Promise( resolve => {
             
            this.model.superficie_total = 0;
            if ( parcelas == null || parcelas.length === 0 ) {
                this.model.superficie_total = 0;
                resolve(true);
            } else {
                for (let index = 0; index < parcelas.length; index++) {
                    if ( parcelas[index]?.superficie_cultivada == null || parcelas[index]?.superficie_cultivada?.length === 0 ) {
                    } else {
                        this.model.superficie_total += parseFloat((parcelas[index]?.superficie_cultivada as string).replace(',', '.'));
                    }
                    if ( (index + 1) >= parcelas.length ) {
                        resolve(true);
                    } 
                }
            }
        });
        
    }

    /**
     *  Dividir kg totales por ha
    */

    public divideKgByHa( superficie_total: number) {
        let contador = 1;
        if ( superficie_total === 0 ) {
            for (let index = 0; index < 8; index++) {
                this.model['kg_ha' + contador] = '';
                contador++;
            }
        } else {
            for (let index = 0; index < 7; index++) {
                if (this.model['dosis' + (index + 1 ) ] != null ) { 
                   
                    if (this.model['dosis' + (index + 1 ) ] === 0 ) {
                        this.model['kg_ha' + (index + 1) ] = '';
                    } else {
                        const kg = parseFloat(this.model['dosis' + (index + 1 )]?.toString() ?? '' );
                        const superficie = superficie_total;
                        this.model['kg_ha' + (index + 1) ] = Utils.decimalFormat( (kg / superficie ), 2, '.', ',', 2);
                    }
                    
                }
                if ( index + 1 ) {
                    this.model.parcelas_sup =  Utils.decimalFormat( (this.model.superficie_total?.toString() ?? ''), 2, '.', ',', 2);
                }
                
            }
        }
    }

    selectNumColumnsInfoDelete() {
       
        switch (this.appName) {
        case 'pyf':
            this.distributionColInfoProduct = 4;
            break;
        case 'almassora':
            this.distributionColInfoProduct = 2;
            break;
        case 'viverosblanco':
            this.distributionColInfoProduct = 2;
            break;
        case 'torrepacheco':
            this.distributionColInfoProduct = 2;
            break;
        case 'onda':
            this.distributionColInfoProduct = 2;
            break;
    
        default:
            this.distributionColInfoProduct = 3;
            break;
        }
    }

    public filtrarTratamientos(value: string, from = 'switch_aplicados') {
        if (from === 'switch_aplicados') {
            this.aplicado.selected = this.aplicado.selected === value ? '' : value;
        } 

        this.filter.filter();
        
        if ( this.betweenDates) {
            this.sumHours();
        }
    }

    public async downloadCsv() {
  
        const arrayFormFields = [
            this.formFields,
            this.formFieldsProductos
        ];
      
        let campoFinal = 'prioridad';

        if (this.applicationType === 'cropgest') {
            arrayFormFields.push(this.formFieldsPac);
            arrayFormFields.push(this.formFieldsAbonado);
            campoFinal = 'fecha_asesoramiento';
        }

        if (this.appName === 'donjardin' || this.appName === 'coliberica' || this.appName === 'demogardengest') {
            arrayFormFields.push(this.formFieldsOrdenTrabajo);
            arrayFormFields.push(this.formFieldsObs);
            campoFinal = 'observaciones';
        }

        const csvContent = createCsv(arrayFormFields, this.filter.value, campoFinal);
      
        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;

        csvLink.download = 'Fertilizantes.csv';
        csvLink.href = encodeURI(csvContent);
        csvLink.click();
      
    }

    
      
    public sumHours() {
        this.totalHoras = moment(new Date('October 1, 2014 00:00:00'));
        this.auxTotalHoras = moment(new Date('October 1, 2014 00:00:00'));
        let contador = 0;
        (this.filter.value || []).forEach((tareas, index ) => {
      
            if ( tareas.horas_totales != null ) {
         
                if (tareas.horas_totales.length > 0) {
                    contador++;
                    const horaMinuto = (tareas.horas_totales as string).split(':');
                    this.totalHoras = moment(this.totalHoras).add(horaMinuto[0], 'hours').add(horaMinuto[1], 'minutes');
                }
            }
      
            if ( index + 1 >= (this.filter.value as TareaFertiModel[]).length ){
        
                let minutos = moment(this.totalHoras).format('mm');
            
                // por algún motivo no le añade una hora de más
                let differencia = moment(this.totalHoras).diff(this.auxTotalHoras);
            
                if ( minutos.toString().length === 0) {
                    minutos = '00';
                }
      
                if ( minutos.toString().length === 1) {
                    minutos = '0' + minutos.toString();
                }
                differencia = differencia / (1000 * 60 * 60);
                differencia = Math.abs(Math.round(differencia));
      
                this.totalHoras = differencia + ':' + minutos;
            
                if ( contador === 0){
                    this.totalHoras = '00:00';
                }
      
            }
        
          
        });
      
        if ( contador === 0){
            this.totalHoras = '00:00';
        }
        this.filter.filter();
    }
      
    getFechaInicio( event: string) {
        this.fecha.inicio = event;
        this.filter.filter();
        this.updateFooter();
        
    }
    getFechaFin( event: string) {
        this.fecha.fin = event;
        this.filter.filter();
        this.updateFooter();
    }
      
    ngAfterViewInit() {
        this.tareasApi.fertilizantes.GET.response(() => {
            this.updateFooter();
        });
    }
      
}
