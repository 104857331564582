import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
// @ts-expect-error No viene tipado.
import * as octicons from 'octicons';

import type { OnInit } from '@angular/core';
@Directive({
    selector: '[appOcticon]'
})
export class OcticonDirective implements OnInit {

    @Input() octicon = '';
    @Input() color = '';
    @Input() width = 0;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

    ngOnInit(): void {
        const el: HTMLElement = this.elementRef.nativeElement;
        el.innerHTML = octicons[this.octicon].toSVG();

        const icon: Node = el.firstChild ?? new Node();
        if (this.color) {
            this.renderer.setStyle(icon, 'color', this.color);
        }
        if (this.width) {
            this.renderer.setStyle(icon, 'width', this.width);
            this.renderer.setStyle(icon, 'height', '100%');
        }
    }
}
