<br>

<div style="width: auto; overflow: auto">
    <app-common-list-table
        [request]="getRequest"
        [cols]="cols"
        [minWidth]="1000"
        [minHeight]="400"
        [globalConditionalStyle]="globalConditionalStyle"
        [globalVisualTransform]="globalVisualTransform"
        [filtering]="filtering"
        [showExportCsv]="true">

        <ng-template #thStart>
            <th>
                <p class="d-flex justify-content-center" style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>
        <ng-template #tdStart let-data>
            <td>
                <div class="px-3">
                    <app-action-buttons
                        (showRowButtonEvent)="show('info', data)"
                        (editRowButtonEvent)="edit(data)"
                        (deleteRowButtonEvent)="show('delete', data)">
                    </app-action-buttons>
                </div>
            </td>
        </ng-template>
        <ng-template #header>
            <div *ngIf="showWarning" class=" d-flex align-content-start" style=" color: red; font-weight: bold;">Debes
                seleccionar todas las opciones del marco para generar la grafica</div>
            <div class="d-flex bg-white rounded">
                <div class="flex-fill d-flex align-items-center m-2">
                    <span class="mr-2">Semana</span>
                    <p-dropdown [options]="weeks" [(ngModel)]="currentWeek" optionLabel="name" (onChange)="filterValues()">
                    </p-dropdown>
                </div>
                <div class="flex-fill d-flex align-items-center m-2">
                    <span class="mr-2">Año</span>
                    <p-dropdown [options]="years" [(ngModel)]="currentYear" optionLabel="name" (onChange)="filterValues()">
                    </p-dropdown>
                </div>
                <div class="flex-fill d-flex align-items-center m-2">
                    <span class="mr-2">Trampas </span>
                    <p-dropdown [options]="trampas" [(ngModel)]="trampaSelected" optionLabel="name" (onChange)="filterValues()">
                    </p-dropdown>
                </div>
                <div class="flex-fill d-flex align-items-center m-2">
                    <span class="mr-2">Plagas </span>
            
                    <p-multiSelect defaultLabel="Selecciona plaga" [options]="plagasList" [(ngModel)]="plagaSelected"
                        [filter]="false" optionLabel="name" [panelStyle]="{minWidth:'5em'}">
                    </p-multiSelect>
            
                </div>
            
                <div class="d-flex align-items-center m-2">
                    <button class="btn btn-info" (click)="show('grafica',model)"><i class="fa fa-bar-chart" aria-hidden="true"></i>&nbsp;Generar grafica</button>
                </div>
            
            
            </div>
            
        </ng-template>
    </app-common-list-table>
</div>

<p-dialog
    *ngIf="canShow['info']"
    header="Detalle del registro"
    [(visible)]="canShow['info']"
    (onShow)="scrollToTop()">

  <div style="width: 80vw !important">
    <div style="max-width: 500px;">
        <app-form-builder
            [fields]="formFields"
            [distributionColumns]="4"
            [model]="model">
        </app-form-builder>
    </div>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
    </div>
  </div>
  <br>

</p-dialog>

<p-dialog
    *ngIf="canShow['delete']"
    header="Eliminar registro"
    [(visible)]="canShow['delete']"
    (onShow)="scrollToTop()"
>

  <div style="width: 80vw !important">
    <app-form-builder
            [fields]="formFields"
            [distributionColumns]="4"
            [model]="model">
    </app-form-builder>
    <br>
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
              (click)="deleteRegister()"
              [request]="deleteRequest"
              [mode]="deleteRequestButtonType">
      </app-request-button>
    </div>
    <br>
    <p *ngIf="(deleteRequest?.hasError === undefined) && !deleteRequest?.isLoading"
       style="color: red; text-align: center">
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br>

</p-dialog>

<p-dialog 
    *ngIf="canShow['grafica']"
    header="Cantidad Plagas " 
    [(visible)]="canShow['grafica']"  
    [responsive]="true" 
    [style]="{width: '70vw', minWidth: '40vw'}"
>
    <img style="display: block; margin: 0 auto;" *ngIf="loadingCharts" src="assets/icon/load.gif" alt="" srcset="">

    <div *ngIf="!loadingCharts">
        <p-chart  type="line" [data]="dataPlagasChart" [options]="basicOptions">
        </p-chart>
    </div>

    <p-footer>
        <button class="btn btn-dark mx-2" (click)="hide('grafica')">Cerrar</button>
    </p-footer>
</p-dialog>