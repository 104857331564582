<p-panel>
  <p-header>Datos del producto</p-header>
    <div class="form-row">
      <div class="form-group col-sm-4">
        <span>Nombre</span>
        <br>
        <input #search
          type="text"
          placeholder="Nombre..."
          (keyup)="request.cancelablePerform({p3: search.value, nombre: '', materia_activa: ''})"
          [value]="producto.nombre"
          pInputText />
      </div>
      <div class="form-group col-sm-4">
        <span>Usos</span>
        <br>
        <p-dropdown
          [(ngModel)]="usos.selected"
          (onChange)="applyFilters()"
          [options]="usos.filtered"></p-dropdown>  
      </div>
      <div class="form-group col-sm-4">
        <span>Agentes</span>
        <br>
        <p-dropdown
          [(ngModel)]="agentes.selected"
          (onChange)="applyFilters()"
          [options]="agentes.filtered"></p-dropdown>  
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-2">
        <span style="font-weight: bold">N Registro</span>
        <p style="margin-top: 4px">{{agente.num_registro}}</p>
      </div>
      <div class="form-group col-sm-2">
        <span style="font-weight: bold">Dosis</span>
        <div *ngIf="agentes.selected">
          <p style="margin-top: 4px">
            {{agente.dosis1}}
            <span *ngIf="agente.dosis2 && agente.dosis2 !== 0">
              - {{agente.dosis2}}
            </span>
            {{agente.unidad_medida}}
          </p>
        </div>
      </div>
      <div class="form-group col-sm-2">
        <span style="font-weight: bold">Plazo Seg.</span>
        <p style="margin-top: 4px">{{agente.plazo_seguridad}}</p>
      </div>
      <div class="form-group col-sm-2">
        <span style="font-weight: bold">F. Caducidad</span>
        <p style="margin-top: 4px">{{agente.fecha_caducidad}}</p>
      </div>
      <div class="form-group col-sm-4">
        <span style="font-weight: bold">Materia Activa</span>
        <p style="margin-top: 4px">{{agente.composicion}}</p>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-sm-3">
        <span>Finca</span>
        <br>
        <p-dropdown
          [(ngModel)]="fincas.selected"
          [options]="fincas.values"></p-dropdown>
      </div>
      <div class="form-group col-sm-2">
        <span>Estado</span>
        <br>
        <p-dropdown
          [(ngModel)]="estado.selected"
          [options]="estado.values"></p-dropdown>
      </div>
      <div class="form-group col-sm-2">
        <span>Precio</span>
        <br>
        <input type="text" placeholder="Precio..." [(ngModel)]="data.precio" pInputText />
      </div>
      <div class="form-group col-sm-2">
        <span>Unidades</span>
        <br>
        <input type="text" placeholder="Unidades..." [(ngModel)]="data.unidades" pInputText />
      </div>
      <div class="form-group col-sm-1">
        <span>N</span>
        <br>
        <input type="text" placeholder="N..." [(ngModel)]="data.n" pInputText />
      </div>
      <div class="form-group col-sm-1">
        <span>P</span>
        <br>
        <input type="text" placeholder="P..." [(ngModel)]="data.p" pInputText />
      </div>
      <div class="form-group col-sm-1">
        <span>K</span>
        <br>
        <input type="text" placeholder="K..." [(ngModel)]="data.k" pInputText />
      </div>
    </div>
</p-panel>
<br>
<p-panel>
  <p-header>
    <div class="d-flex justify-content-between">
      <div>
        <div *ngIf="request.isLoading" style="
        display: inline-block;
        margin-right: 10px;
        position: relative;
        top: 3px;">
          <p-progressSpinner
            [style]="{width: '20px', height: '20px'}"
            strokeWidth="6"
            animationDuration="1.5s"></p-progressSpinner>
        </div>
        <span *ngIf="request.hasError"
              style="margin-right: 10px"
              tooltipPosition="bottom"
              pTooltip="Sin conexion a internet">
        <i class="fa fa-exclamation-triangle"  style="color: black"></i>
      </span>
        <p style="display: inline-block">Resultados de la busqueda</p>
      </div>
    </div>
  </p-header>
  <p *ngIf="aplicacion">{{aplicacion}}</p>
  <p-table     
    [value]="[filtering.value]"
    [style]="{minWidth:'400px'}"
    [paginator]="true"
    selectionMode="single"
    (onRowSelect)="applyFilters()"
    [(selection)]="productos.selected"
    [rows]="15">
    
    <ng-template pTemplate="header">
      <tr style="font-size: 0.8em">
        <th *ngFor="let col of cols">
          {{col.header}}
          <div [ngSwitch]="col.filter">
            <p-dropdown *ngSwitchCase="'dropdown'"
              [options]="dropdown[col.field]"
              [style]="{'width':'100%', 'fontSize': '0.7em'}"
              (onChange)="setDropdownSelectOn($event.value,col.field)">
            </p-dropdown>

            <p-multiSelect *ngSwitchCase="'multiselect'"
              [options]="multiselect[col.field]"
              [(ngModel)]="filters.multiselect[col.field]"
              [style]="{'width':'100%', 'fontSize': '0.7em'}"
              (onChange)="setMultiSelectOn($event.value, col.field)">
            </p-multiSelect>

            <p-calendar *ngSwitchCase="'calendar'"
              [(ngModel)]="filters.dateRange[col.field]"
              (onClose)="setDateRangeOn()"
              (onClearClick)="cleanDateRangeOn(col.field)"
              [monthNavigator]="true"
              [yearNavigator]="true"
              yearRange="2005:2018"
              showButtonBar="true"
              selectionMode="range"
              readonlyInput="true">
            </p-calendar>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-data>
      <tr [pSelectableRow]="data" style="font-size: 0.8em">
        <td *ngFor="let col of cols">
          <div *ngIf="col.field !== 'ficha'">
            {{data[col.field]}}
          </div>
          <div *ngIf="col.field === 'ficha'">
            <a [href]="'http://www.mapama.gob.es//agricultura/pags/fitos/registro/productos/pdf/' + data.n_registro + '.pdf'"
              target="_blank">
              <button class="btn btn-primary">
                <span class="fa fa-file" style="margin-right: 10px"></span>
                Ficha
              </button> 
            </a>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-panel>
<br>
<p-panel>
  <p-header>Observaciones</p-header>
  <!-- form (ngSubmit)="onSubmit()" -->
  <div class="form-row">
    <div class="form-group col-sm-12">
      <textarea [(ngModel)]="data.observaciones" pInputTextarea></textarea>
      <br>
      <div class=" d-flex justify-content-center">
        <button
          [disabled]="stockApi.productosPost.isLoading"
          class="btn btn-success"
          style="margin-right: 20px;"
          (click)="onSubmit()">
            Guardar
        </button>
        <button class="btn btn-secondary" (click)="goBack()">Volver</button>
      </div>
      <p-messages [(value)]="msgs"></p-messages>
    </div>
  </div>
  <!-- /form -->
</p-panel>
<br>
