import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Filtering } from '../../../../service/filtering/filtering';
import { BaseView } from '../../../base-view';
import { StorageManager } from '../../../../common/storage-manager.class';
import { InputType } from '../../../../common/components/form-builder/form-builder.component';
import { TareasApiService } from '../../../../service/api/tareas-api.service';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { environment } from 'src/environments/environment';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { list } from 'src/app/common/classes/request-builder';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { RequestButtonTypes } from 'src/app/common/components/request-button/request-button.component';
import { createCsv } from 'src/app/common/createCsv';

import type { OnInit, AfterViewInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { TareaOtraModel } from 'src/app/models/tareas/tarea-otra.model';
import { ColInterface } from 'src/app/common/components/common-list-table/col.interface';
import { ColStylesInterface } from 'src/app/common/components/common-list-table/col-styles.interface';

@Component({
    selector: 'app-otros',
    templateUrl: './otros.component.html',
    styleUrls: ['./otros.component.scss']
})
export class OtrosComponent extends BaseView<TareaOtraModel> implements OnInit, AfterViewInit {
    public years = [
        { label: '...', value: 0 },
    ];
    public aplicado = {
        selected: ''
    };

    public showTareasGenericasObservaciones = environment.features.showTareasGenericasObservaciones;


    public selectedYears = 0;
    public modalShowCampaign = false;
    public canAddTareas = environment.features.canAddTareas;
    public showCampaign = environment.features.showTareasOtrasCampaign;
    public btnAnadir = 'Añadir Tarea';
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public rolUser = StorageManager.getUser().rol;
    public canDuplicateTareas = environment.features.canDuplicateTareas;
    public showRecetaPascualCabedo = environment.features.showRecetaPascualCabedo;
    public showRecetaAgrogest = environment.features.showRecetaAgrogest;
    public showEnviarCorreo = environment.features.showEnviarCorreo;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public parcelaName = environment.features.parcelaName;
    public showTareasOtrosDuracion = environment.features.showTareasOtrosDuracion;
    public showTareasOtrasProducto = environment.features.showTareasOtrasProducto;
    public showTareasOtrasCantidad = environment.features.showTareasOtrasCantidad;
    public showTareasProcura = environment.features.showTareasProcura;
    public serveUrl = environment.serverUrl;
    public changeLabelProducto = false;
    public showTareasOtrosImagen = environment.features.showTareasOtrosImagen;
    public betweenDates = environment.features.betweenDatesGenericas;

    public timeStartStopTareas = environment.features.timeStartStopTareas;

    public showPrescripcion = false;

    public receta: TareaOtraModel = {};
    public showReceta = false;

    public getRequest: RequestHandler<TareaOtraModel[]> = this.tareasApi.otras.GET;
    public deleteRequest: RequestHandler<TareaOtraModel> = this.tareasApi.otras.DELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public showTareasBioExportCsv = environment.features.showTareasBioExportCsv;


    public cols: ColInterface[] = [
        { 
            field: 'finca', 
            header: this.fincaName, 
            filter: '' 
        },
        { 
            field: 'sector', 
            header: this.sectorName, 
            filter: '' 
        },
        { 
            field: 'parcelas', 
            header: this.parcelaName, 
            filter: '' 
        },
        { 
            field: 'tarea', 
            header: this.esViveroOGardenGest() ? 'Genérica' : 'Tarea', 
            filter: '' 
        },
        { 
            field: 'fecha', 
            header: 'Fecha', 
            filter: '' 
        },
        { 
            field: 'duracion', 
            header: 'Duración', 
            visible: this.showTareasOtrosDuracion && !environment.features.tareasHorasTotales, 
            filter: '' 
        },
        { 
            field: 'producto', 
            header: 'Productos', 
            visible: this.showTareasOtrasProducto, 
            filter: '' 
        },
        { 
            field: 'maquinaria', 
            header: 'Maquinaria', 
            filter: '' 
        },
        { 
            field: 'cantidad', 
            header: 'Cantidad', 
            visible: this.showTareasOtrasCantidad, 
            filter: '' 
        },
        { 
            field: 'hora_inicio', 
            header: 'Hora Inicio', 
            filter: '' 
        },
        { 
            field: 'hora_fin', 
            header: 'Hora Fin', 
            filter: '' 
        },
        { 
            field: 'horas_totales', 
            header: 'Horas Totales', 
            visible: environment.features.tareasHorasTotales, 
            filter: '' 
        },
        { 
            field: 'aplicado', 
            header: 'Aplicado', 
            visible: this.appName === 'ava', 
            filter: '' 
        },
        { 
            field: 'nombres_asignados', 
            header: 'Asignador', 
            filter: '', 
            visible: environment.features.showAssignUserTasksFitosanitario || environment.features.showAssignUserInAllTasks 
        },
      
    ];

    public fecha: { inicio: string; fin: string } = {
        inicio: '',
        fin: '',
    };

    public formFields: ItemInterface<object>[] = [
        {
            field: 'finca',
            name: this.fincaName,
            label: this.fincaName,
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'sector',
            name: this.sectorName,
            label: this.sectorName,
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'parcelas',
            name: this.parcelaName,
            label: this.parcelaName,
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'fecha',
            name: 'Fecha',
            label: 'Fecha',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'fecha_fin',
            name: 'Fecha Fin',
            label: 'Fecha Fin',
            inputType: { type: InputType.TEXT },
            visible: this.appName === 'fotoagricultura'
        },
        {
            field: 'hora_inicio',
            name: 'Hora Inicio',
            label: 'Hora Inicio',
            inputType: { type: InputType.TEXT },
            visible: environment.features.timeStartStopTareas
        },
        {
            field: 'hora_fin',
            name: 'Hora Fin',
            label: 'Hora Fin',
            inputType: { type: InputType.TEXT },
            visible: environment.features.timeStartStopTareas
        },
        {
            field: 'horas',
            name: 'Horas',
            label: 'Horas',
            inputType: { type: InputType.TEXT },
            visible: !this.showTareasOtrosDuracion
        },
        {
            field: 'horas_totales',
            name: 'Horas',
            label: 'Horas',
            inputType: { type: InputType.TEXT },
            visible: this.timeStartStopTareas
        },
        {
            field: 'tarea',
            name: this.esViveroOGardenGest() ? 'Genéricas' : 'Tareas',
            label: this.esViveroOGardenGest() ? 'Genéricas' : 'Tareas',
            visible: this.appName !== 'sequiador' && this.appName !== 'SIEX',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'num_albaran',
            name: 'Nº albarán',
            label: 'Nº albarán',
            inputType: { type: InputType.EDIT_TEXT },
            visible: environment.appName === 'pyf'
        },
        {
            field: 'obs',
            name: 'Observaciones',
            label: 'Observaciones',
            inputType: { type: InputType.DROPDOWN_SEARCH },
            values: list(['...', null], 'Total', 'Parcial', 'Por bandas'),
            visible: environment.appName === 'pyf'
        },
        {
            field: 'procura',
            name: 'Procura',
            label: 'Procura',
            inputType: { type: InputType.TEXT },
            visible: this.showTareasProcura
        },
        {
            field: 'trabajadores_nombres',
            name: 'Trabajadores',
            label: 'Trabajadores',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'maquinaria',
            name: 'Maquinaria',
            label: 'Maquinaria',
            inputType: { type: InputType.TEXT }
        },
        {
            field: 'producto',
            name: 'Productos',
            label: 'Productos',
            inputType: { type: InputType.TEXT },
            visible: this.showTareasOtrasProducto
        },
        {
            field: 'cantidad',
            name: 'Cantidad',
            label: 'Cantidad',
            inputType: { type: InputType.TEXT },
            visible: this.showTareasOtrasCantidad
        },
        {
            field: 'estado_tarea',
            name: 'Estado de la Tarea',
            label: 'Estado de la Tarea',
            inputType: { type: InputType.TEXT },
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
        {
            field: 'empresa_pagadora',
            name: 'Empresa Pagadora',
            label: 'Empresa Pagadora',
            inputType: { type: InputType.TEXT },
            visible: this.appName === 'onda' || this.appName === 'lacooperativa'
        },
        {
            field: 'actividad_agraria',
            name: 'Actividad Agraria',
            label: 'Actividad Agraria',
            inputType: { type: InputType.TEXT },
            visible: this.appName === 'sequiador' || this.appName === 'SIEX'
        },
        {
            field: 'tareas_maquinaria',
            name: 'Tareas Maquinarias',
            label: 'Tareas Maquinaria',
            inputType: { type: InputType.TEXT },
            visible: this.appName === 'sequiador' || this.appName === 'SIEX'
        },
        {
            field: 'tareas_manuales',
            name: 'Tareas Manuales',
            label: 'Tareas Manuales',
            inputType: { type: InputType.TEXT },
            visible: this.appName === 'sequiador' || this.appName === 'SIEX'
        },
        {
            field: 'observaciones',
            label: 'Observaciones',
            inputType: { type: InputType.TEXTAREA },
            visible: this.appName === 'ava'
        },
        {
            field: 'aplicado',
            label: 'Aplicado',
            inputType: { type: InputType.TEXT },
            visible: this.appName === 'ava'
        }, 
        {
            field: 'nombres_asignados',
            label: 'Trabajadores asignados',
            inputType: { type: InputType.TEXT },
            visible: environment.features.showAssignUserTasksGenericas || environment.features.showAssignUserInAllTasks
        },
    ];

    public formFieldsObs: ItemInterface<object>[] = [
        {
            field: 'observaciones',
            label: 'Observaciones',
            inputType: { type: InputType.EDIT_TEXTAREA },
            visible: this.showTareasGenericasObservaciones
        },
    ];

    public filter: Filtering<TareaOtraModel> = new Filtering<TareaOtraModel>();

    public imagenAdjunto = '';
    totalHoras: moment.Moment | string = '';
    auxTotalHoras: moment.Moment | string = '';


    constructor(public tareasApi: TareasApiService,
        private dashboardEvents: DashboardService,
        private router: Router) {
        super(
            dashboardEvents,
            tareasApi.otras.GET,
            tareasApi.otras.DELETE
        );
    }
    ngAfterViewInit(): void {
        if (this.showCampaign) {

            this.fillCampaign();
            this.filter.addFilter((copy: TareaOtraModel[] | void) => (copy as TareaOtraModel[]).filter(row => {

                if (this.selectedYears === 0) {
                    return true;
                } else {
                    return this.compareDates(row);
                }
            }));
        }
        this.tareasApi.otras.GET.response(() => {
            this.updateFooter();
        });
    }

    ngOnInit() {

        this.dashboardEvents.setSubmenuItem('tareas-otros');
        if (this.showRecetaAgrogest) {
            this.showPrescripcion = true;
        }
        if (this.appName === 'ava'){
            this.aplicado.selected = 'No';
        }

        this.filter.addFilter((copy: TareaOtraModel[] | void) => (copy as TareaOtraModel[]).filter(row => {
            if (this.fecha.inicio && this.fecha.fin) {
                if (row.fecha == null) {
                    return false;
                } else {
                    const arrayFecha = row.fecha.split('/');
                    const fechaAux = arrayFecha[2] + '-' + arrayFecha[1] + '-' + arrayFecha[0];

                    if (
                        (moment(moment(this.fecha.inicio).format('YYYY-MM-DD')).diff(fechaAux) <= 0) && 
                        (moment(moment(this.fecha.fin).format('YYYY-MM-DD')).diff(fechaAux) >= 0)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }

            } else {
                return true;
            }
        }));

        this.filter.addFilter((copy: TareaOtraModel[] | void) => (copy as TareaOtraModel[]).filter(row => {

            switch (this.aplicado.selected) {
            case 'Si':
                return [true, 'Si', 1].includes(row.aplicado ?? '');
            case 'No':
                return [null, undefined, 'No', 0].includes(row.aplicado?.toString() ?? '');
            default:
                return true;
            }
        }));

        /**
             *  Activado la asignacion de usuarios en la tabla tareas
             *  Filtraremos las tareas si en caso de que el usuario se encuentre como asignado en la tarea
             */
        // if (environment.features.showAssignUserTasksFitosanitario || environment.features.showAssignUserInAllTasks) {
        if (this.appName === 'ava') {
            /** Solo el que tiene el rol de admin podra ver todas las tareas */
            if (StorageManager.getUser().rol !== 'admin') {
                this.filter.addFilter((copy: TareaOtraModel[] | void) => (copy as TareaOtraModel[]).filter(row => {

                    if (row.referencia_usuario != null) {

                        const idTrabajador = StorageManager.getUser().id;
                        const listaTrabajadores = (row.referencia_usuario as string).split(';');
                        if (listaTrabajadores.includes(idTrabajador)) {
                            return true;
                        }
                    }
                    return false;
                }));
            }
        }// Fin filtro asignados
    }


    public override show(modal: string, data: TareaOtraModel) {
        super.show(modal, data);
        this.getVisitImages();
    }

    public globalVisualTransform(value: string, col: ColInterface, row: TareaOtraModel) {

        if (col.field === 'parcelas') {
            return (value.length > 50) ? value.slice(0, 50 - 1) + '…' : value;
        }

        if (col.field === 'duracion') {
            const fin = moment(row.hora_fin, 'HH:mm');
            const inicio = moment(row.hora_inicio, 'HH:mm');

            if (!inicio.isValid() || !fin.isValid()) {
                return '';
            }

            const duracion = fin.subtract(inicio.get('hours'), 'hours').subtract(inicio.get('minutes'), 'minutes').format('HH:mm');
            return duracion;
        }
        return value;
    }

    public esViveroOGardenGest() {
        if (this.serveUrl.indexOf('gardengest') !== -1 || this.serveUrl.indexOf('viverogest') !== -1) {
            return true;
        } else {
            return false;
        }
    }

    public add() {
        this.router.navigate(['dashboard', 'tareas-crear-otra']);
    }

    public edit(data: TareaOtraModel) {
        this.router.navigate(['dashboard', 'tareas-editar-otra', data.id]);
    }

    public duplicate(data: TareaOtraModel) {
        this.router.navigate(['dashboard', 'tareas-duplicar-otra', data.id]);
    }

    public override deleteRegister() {
        if (this.model.id) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public generarReceta(data: TareaOtraModel) {
        this.receta = data;
        this.showReceta = true;
    }

    public enviarCorreo() {
        html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement(), { scale: 2 }).then(canvas => {
            const adjunto = canvas.toDataURL('image/png');
            let email = '';
            if (this.receta.finca_email) {
                email = this.receta.finca_email;
            } else if (this.receta.cliente_email) {
                email = this.receta.cliente_email;
            } else {
                email = '';
            }
            if (email !== '') {
                this.tareasApi.enviarRecetaCaptura.perform({
                    email,
                    generado: adjunto,
                    finca: this.receta.finca_asociada,
                    tratamiento: this.receta.tratamiento_nombre,
                } as never);
            } else {
                this.tareasApi.enviarRecetaCaptura.hasError = true;
                this.tareasApi.enviarReceta['error'] = 'El cliente o finca no tiene un email asociado';
            }
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        ).then(() => {
            console.log('Adjuntos generados.');
            return;
        }, error => {
            console.log(error);
            this.tareasApi.enviarRecetaCaptura.hasError = true;
            this.tareasApi.enviarReceta['error'] = 'Error al envíar email';
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }

    public imprimirReceta() {
        html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLElement(), { scale: 2 }).then(canvas => {
            const downloadReceta = document.getElementById('downloadReceta') as HTMLAnchorElement;
            downloadReceta.href = canvas.toDataURL('image/png');
            downloadReceta.download =
                (this.receta.cliente_nombre || '.').replace('.', '')
                + ' - ' +
                (this.receta.finca || '.').replace('.', '');
            downloadReceta.click();
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }

    modalCampaign() {
        this.modalShowCampaign = true;
    }

    closeModalShowCampaign() {
        this.modalShowCampaign = false;
    }

    selectedCampaign() {
        this.filter.filter();
    }

    compareDates(row: TareaOtraModel) {

        if (row.fecha == null || row.fecha.length === 0) {
            return false;
        }

        const auxFecha = row.fecha.split('/');
        const nuevaFecha = moment(auxFecha[2] + '-' + auxFecha[1] + '-' + auxFecha[0]);
        const fechaComparar = moment('20' + (this.selectedYears).toString() + '-07' + '-01');
        const fechaMax = moment('20' + (this.selectedYears + 1).toString() + '-06' + '-30');

        if ((nuevaFecha > fechaComparar) && (nuevaFecha < fechaMax)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     *  Rellena el listado de campañas
     */
    fillCampaign() {
        const fechaActual = new Date().getFullYear();
        let fechaDosDigitos = 0;

        for (let index = 2020; index <= fechaActual; index++) {

            fechaDosDigitos = parseInt((index.toString()).substring(2, 4), 10);
            this.years.push({ 
                label: environment.features.applicationType === 'cropgest' ? 
                    index.toString() : (index.toString() + '-' + (fechaDosDigitos + 1 ).toString()), 
                value: fechaDosDigitos 
            });

        }
    }

    public getVisitImages() {

        if (this.model.adjunto) {
            fetch(environment.serverUrl + 'tareas_otros/' + (this.model.adjunto || {}))
                .then((r) => {
                    if (r.status === 200) {
                        this.imagenAdjunto = environment.serverUrl + 'tareas_otros/' + (this.model.adjunto || {});
                    }
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
        } else {
            this.imagenAdjunto = '';
        }
    }

    public verAdjunto() {
        if (this.model.adjunto) {
            const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
            myWindow?.location.assign(environment.serverUrl + 'tareas_otros/' + this.model.adjunto);
            myWindow?.focus();
        } else {
            alert('Esta máquina no tiene fotos.');
        }
    }

    public override sum() {
        this.totalHoras = moment(new Date('October 1, 2014 00:00:00'));
        this.auxTotalHoras = moment(new Date('October 1, 2014 00:00:00'));
        let contador = 0;
        (this.filter.value || []).forEach((tareas, index) => {

            if (tareas.horas != null) {

                if (tareas.horas.length > 0) {
                    contador++;
                    const horaMinuto = (tareas.horas as string).split(':');
                    this.totalHoras = moment(this.totalHoras).add(horaMinuto[0], 'hours').add(horaMinuto[1], 'minutes');
                }
            }

            if (index + 1 >= (this.filter.value as TareaOtraModel[]).length) {

                let minutos = moment(this.totalHoras).format('mm');

                let differencia = moment(this.totalHoras).diff(this.auxTotalHoras);

                if (minutos.toString().length === 0) {
                    minutos = '00';
                }

                if (minutos.toString().length === 1) {
                    minutos = '0' + minutos.toString();
                }

                differencia = differencia / (1000 * 60 * 60);
                differencia = Math.abs(Math.floor(differencia));

                this.totalHoras = differencia + ':' + minutos;

                if (contador === 0) {
                    this.totalHoras = '00:00';
                }

            }


        });

        if (contador === 0) {
            this.totalHoras = '00:00';
        }
        this.filter.filter();
    }

    getFechaInicio(event: string) {
        this.fecha.inicio = event;
        this.filter.filter();
        this.updateFooter();
    }

    getFechaFin(event: string) {
        this.fecha.fin = event;
        this.filter.filter();
        this.updateFooter();
    }

    public override updateFooter() {
        this.sum();
    }

    public async downloadCsv() {

        const arrayFormFields = [
            this.formFields
        ];

        const csvContent = createCsv(arrayFormFields, this.filter.value, 'observaciones');

        const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;

        csvLink.download = 'Tareas Genéricas.csv';
        csvLink.href = encodeURI(csvContent);
        csvLink.click();
    }

    public filtrarRealizados(value: string, from = 'prioridad') {
        if (from === 'aplicado') {
            this.aplicado.selected = this.aplicado.selected === value ? '' : value;
        }

        this.filter.filter();
    }
    public globalConditionalStyle(value: string, col: ColInterface): ColStylesInterface {



        if (col.field === 'horas') {
            return {
                textAlign: 'center'
            };
        }

        if (col.field === 'prioridad') {
            if (value === 'Alta') {
                return {
                    rowStyle: {
                        backgroundColor: '#f44242'
                    },
                };
            }
            else if (value === 'Realizada') {
                return {
                    rowStyle: {
                        backgroundColor: '#00ff00'
                    },
                };
            }
        }
        return {};
    }
}
